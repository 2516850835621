/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
/* Common Platform CSS */
@-ms-viewport {
  width: device-width;
  user-zoom: fixed;
  max-zoom: 1;
  min-zoom: 1;
}
@media (orientation: landscape) {
  .km-tablet .km-on-ios.km-horizontal.km-web:not(.km-ios-chrome) {
    position: fixed;
    bottom: 0;
  }
}
.km-root {
  font-size: .92em;
}
.km-root.km-retina input,
.km-root.km-retina select,
.km-root.km-retina textarea {
  font-size: 1em;
}
.km-root a {
  color: inherit;
}
.km-tablet {
  font-size: 1em;
}
.km-root *:focus {
  outline-width: 0;
}
.km-root,
.km-pane,
.km-pane-wrapper {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
  overflow-x: hidden;
}
.km-pane-wrapper {
  position: absolute;
}
.km-pane,
.km-shim {
  font-family: sans-serif;
}
.km-pane {
  overflow-x: hidden;
  position: relative;
}
.km-vertical .km-collapsible-pane {
  position: absolute;
  z-index: 2 !important;
  -webkit-transition: -webkit-transform 350ms ease-out;
          transition: transform 350ms ease-out;
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-vertical .km-expanded-splitview .km-collapsible-pane {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.km-expanded-pane-shim {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.km-expanded-splitview .km-expanded-pane-shim {
  display: block;
  z-index: 1;
}
.km-root > * {
  margin: 0;
  padding: 0;
}
.km-root * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.km-content {
  display: block;
}
.km-view,
.km-split-content {
  top: 0;
  left: 0;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
          align-content: stretch;
  vertical-align: top;
}
.k-ff .km-view,
.k-ff .km-pane {
  overflow: hidden;
}
.k-ff18 .km-view,
.k-ff18 .km-pane,
.k-ff19 .km-view,
.k-ff19 .km-pane,
.k-ff20 .km-view,
.k-ff20 .km-pane,
.k-ff21 .km-view,
.k-ff21 .km-pane {
  position: relative;
}
.k-ff .km-view {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.km-content {
  min-height: 1px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  flex-align: stretch;
  width: auto;
  overflow: hidden;
  position: relative;
}
.km-content p,
.km-content h1,
.km-content h2,
.km-content h3,
.km-content h4,
.km-content h5,
.km-content h6 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.km-header,
.km-footer {
  display: block;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.km-header {
  padding: 0;
}
.km-footer {
  background: #1a1a1a;
}
[data-role="layout"] {
  display: none;
}
/**
 * The angular tags will be converted to div kendo-mobile-view
 *
 */
[data-role="view"],
[data-role="drawer"],
kendo-mobile-view,
kendo-mobile-split-view,
kendo-mobile-drawer {
  visibility: hidden;
}
.km-view {
  visibility: visible;
}
.km-header,
.km-footer {
  position: relative;
  z-index: 1;
}
@media all and (-webkit-min-device-pixel-ratio: 10000), not all and (-webkit-min-device-pixel-ratio: 0) {
  .km-view {
    display: table;
  }
  .km-header,
  .km-footer,
  .km-content {
    display: table-row;
  }
  .km-header,
  .km-footer {
    height: 1px;
  }
}
.km-root .k-toolbar,
.km-navbar,
.km-button,
.km-buttongroup,
.km-tabstrip,
.km-blackberry li.km-actionsheet-cancel > a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-background-origin: border-box;
          background-origin: border-box;
  position: relative;
  display: inline-block;
  padding: .4em .7em;
  margin: .1rem;
  overflow: visible;
  text-decoration: none;
}
.km-tabstrip,
.km-root .k-toolbar,
.km-navbar {
  display: block;
  padding: .8em;
  margin: 0;
  width: 100%;
  border-width: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-native-scroller {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-x pan-y;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-scroll-snap-type: proximity;
}
.km-default-content {
  padding: 1em;
}
.km-shim {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10001;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-root .km-shim {
  position: absolute;
}
.km-shim:before {
  content: "\a0";
  height: 100%;
  width: 0;
  display: inline-block;
  vertical-align: middle;
}
.km-shim .k-animation-container {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
  width: auto;
}
/* Loader */
.km-loader {
  top: 50%;
  left: 50%;
  width: 180px;
  height: 130px;
  z-index: 100000;
  padding: 30px 30px;
  position: absolute;
  margin-top: -70px;
  margin-left: -90px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
}
.km-loader h1 {
  font-size: 1rem;
  color: white;
  text-align: center;
  vertical-align: middle;
}
.km-loader .km-loading,
.km-load-more .km-icon,
.km-scroller-refresh .km-icon {
  -webkit-animation: km-spin 1s infinite linear;
          animation: km-spin 1s infinite linear;
  display: block;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  font-size: 35px;
}
.km-loader .km-loading:after,
.km-load-more .km-icon:after {
  color: #ccc;
}
.km-loading-left,
.km-loading-right {
  display: none;
}
@-webkit-keyframes km-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes km-ios-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes km-ios-spin1 {
  from {
    -webkit-transform: rotate(-135deg);
  }
  to {
    -webkit-transform: rotate(225deg);
  }
}
@keyframes km-ios-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes km-ios-spin1 {
  from {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  to {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
}
/* Stretched View */
.km-stretched-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.km-stretched-view > * {
  width: 100%;
}
.km-stretched-view > .km-pane-wrapper {
  position: static;
}
.km-stretched-view > .km-pane-wrapper > .km-pane {
  position: static;
}
.km-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 100000;
}
.km-root.km-native-scrolling,
.km-root.km-native-scrolling .km-view,
.km-root.km-native-scrolling .km-splitview .km-pane {
  min-height: 100%;
  height: auto;
  -webkit-transform: none;
  overflow-x: visible;
}
.km-native-scrolling,
.km-native-scrolling .km-pane,
.km-native-scrolling .km-view {
  -ms-touch-action: auto;
}
.km-native-scrolling .km-pane,
.km-native-scrolling .km-view {
  display: block;
}
.km-native-scrolling .km-content {
  -ms-flex: auto;
}
.km-native-scrolling .km-blackberry .km-content {
  min-height: auto;
}
/* Restore position:absolute during animation */
.km-native-scrolling .km-splitview {
  position: absolute;
}
.km-native-scrolling .km-header {
  position: fixed;
  top: 0;
}
.km-native-scrolling .km-android .km-header {
  top: auto;
  bottom: 0;
}
.km-native-scrolling .km-footer {
  position: fixed;
  bottom: 0;
}
.km-native-scrolling .km-android .km-footer {
  top: 0;
  bottom: auto;
}
.km-native-scrolling .km-badge {
  z-index: auto;
}
.km-native-scrolling .km-splitview .km-header,
.km-native-scrolling .km-splitview .km-footer,
.km-native-scrolling .km-popup.km-pane .km-header,
.km-native-scrolling .km-popup.km-pane .km-footer {
  position: absolute;
}
.km-native-scrolling .km-modalview .km-header,
.km-native-scrolling .km-modalview .km-footer {
  position: relative;
}
.km-native-scrolling .km-content {
  width: 100%;
}
.km-native-scrolling .km-shim,
.km-native-scrolling .km-popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  height: auto !important;
}
.km-native-scrolling .km-drawer {
  position: fixed;
  top: 0;
  height: 100% !important;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
}
.km-native-scrolling > .km-pane > .km-loader {
  position: fixed;
}
.km-native-scrolling .km-header,
.km-native-scrolling .km-footer {
  z-index: 2;
}
/* Disabled states */
.km-state-disabled {
  opacity: 0.5;
}
.km-badge,
.km-detail {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-align: center;
  position: absolute;
  z-index: 1;
  height: 2em;
  font-size: .6rem;
  text-shadow: none;
}
.km-badge {
  top: -1em;
  right: -1em;
  line-height: 2em;
  margin-left: .5em;
  min-width: .9em;
  padding: 0 .55em;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.km-tabstrip .km-badge {
  top: -0.2em;
  right: auto;
  margin-left: -1em;
}
/* DetailButtons */
.km-detail {
  position: absolute;
  float: right;
  right: .8rem;
  top: 50%;
  margin-top: -0.7rem;
  width: 1.3rem;
  height: 1.3rem;
  font-size: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-widget .km-detaildisclose {
  font-size: .8em;
}
.k-ff .km-detail {
  width: 1rem;
  height: 1rem;
}
.km-detail .km-text {
  display: none;
}
.km-widget .km-rowinsert:after,
.km-widget .km-rowdelete:after,
.km-widget .km-contactadd:after,
.km-widget .km-detaildisclose:after,
.km-widget .km-rowinsert:before,
.km-widget .km-rowdelete:before,
.km-widget .km-contactadd:before,
.km-widget .km-detaildisclose:before,
.km-detail .km-icon {
  left: .15em;
  top: .15em;
  line-height: 1em;
  font-size: 1em;
  position: absolute;
}
.km-widget .km-detaildisclose:after {
  left: .1em;
  top: .25em;
  text-align: center;
  font-weight: bold;
}
/* Buttons */
.km-button {
  cursor: pointer;
  outline: 0;
  text-align: center;
}
button.km-button {
  display: inline-block;
  font: inherit;
}
.km-button:hover {
  text-decoration: none;
}
.km-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.km-ios .km-state-disabled .km-button,
.km-android .km-state-disabled .km-button,
.km-blackberry .km-state-disabled .km-button,
.km-meego .km-state-disabled .km-button {
  color: #aaa;
  text-shadow: none;
}
.km-root .km-pane .k-button:focus,
.km-root .km-pane .k-button:active,
.km-root .km-pane .k-button:focus:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-buttongroup {
  padding: .4rem .7rem;
  -webkit-margin-collapse: separate;
  margin-collapse: separate;
  margin: .5em auto;
}
.km-widget.km-buttongroup {
  padding: 0;
  border-color: transparent;
  background: none;
  white-space: nowrap;
  display: table;
}
.km-buttongroup > .km-button {
  display: table-cell;
}
.km-widget.km-buttongroup .km-button {
  margin: 0;
  border-width: 1px 0 1px 1px;
  padding: .48em .9em .44em;
}
.km-tablet .km-buttongroup .km-button {
  padding: .4em .8em .34em;
}
.km-widget.km-navbar .km-buttongroup {
  font-size: .95rem;
  line-height: 1em;
  margin: 0 0 .2em;
  display: inline-block;
  height: 1.5em;
  top: -2px;
}
.k-toolbar .km-buttongroup {
  margin: 0;
  display: inline-block;
}
.km-tablet .km-navbar .km-buttongroup {
  top: -1px;
}
.km-widget.km-navbar .km-buttongroup > .km-button {
  font-size: 1em;
  min-width: 4rem;
  text-align: center;
}
.km-tablet .km-navbar .km-buttongroup > .km-button {
  min-width: 6rem;
}
.km-view .km-buttongroup .km-button:last-child {
  border-right-width: 1px;
}
.km-ios .km-buttongroup .km-button {
  font-size: 1.2em;
  font-weight: bold;
}
.km-hide-title {
  display: none;
}
.km-show-title:after {
  display: block;
  content: "\a0";
  height: 0;
}
.km-fill-title:after {
  height: auto;
}
.km-footer .km-show-title:after {
  display: inline-block;
}
.km-view-title,
.km-dialog-title {
  position: relative;
  visibility: visible;
  text-align: center;
  font-size: 1.4em;
  line-height: 2.3em;
  margin-left: auto;
  margin-right: auto;
}
.km-horizontal .km-view-title {
  line-height: 2em;
}
.km-root .k-toolbar,
.km-navbar {
  padding: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
  overflow: hidden;
  display: block;
  border-width: 0 0 1px 0;
  background-color: #fff;
}
.k-ff.km-root .k-toolbar,
.k-ff .km-navbar {
  overflow: visible;
}
.km-navbar .km-button {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.km-navbar .km-no-title {
  padding-top: .7rem;
  padding-bottom: .7rem;
}
.km-horizontal .km-navbar .km-button {
  margin-top: .3rem;
  margin-bottom: .3rem;
}
.km-horizontal .km-navbar .km-no-title {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.km-tablet.km-root .km-no-title {
  padding-top: .55rem;
  padding-bottom: .55rem;
}
.km-tablet .km-navbar .km-button {
  margin-top: .45rem;
  margin-bottom: .45rem;
}
.km-root .km-pane .km-navbar .km-no-title {
  visibility: visible;
  line-height: 0;
}
/* Navbar */
.km-on-ios.km-black-translucent-status-bar.km-app .km-header .km-navbar {
  padding-top: 1.4em;
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
.km-on-ios.km-ios5.km-cordova .km-header .km-navbar,
.km-on-ios.km-ios6.km-cordova .km-header .km-navbar {
  padding-top: 0;
}
.km-leftitem,
.km-rightitem {
  z-index: 1;
  position: absolute;
  right: .5em;
}
.km-popup .km-rightitem {
  right: 0;
}
.km-leftitem {
  left: .5em;
  right: auto;
}
.km-popup .km-leftitem {
  left: 0;
}
/* Center left/right item contents */
.km-leftitem,
.km-rightitem {
  height: 100%;
}
.km-on-ios.km-black-translucent-status-bar.km-app .km-leftitem,
.km-on-ios.km-black-translucent-status-bar.km-app .km-rightitem {
  height: auto;
}
.km-leftitem > *,
.km-rightitem > * {
  display: inline-block;
  vertical-align: middle;
}
.km-leftitem:before,
.km-rightitem:before {
  content: "\a0";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.km-tabstrip {
  padding: .4rem .7rem;
}
.km-horizontal .km-tabstrip {
  padding: .2rem .7rem;
}
.km-tabstrip {
  flex-align: start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -moz-box-pack: start;
  padding: 0;
  text-align: center;
  word-spacing: -1em;
}
.km-tabstrip .km-button {
  word-spacing: normal;
  -webkit-box-shadow: none;
          box-shadow: none;
  vertical-align: bottom;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .km-tabstrip {
    word-spacing: normal;
    width: 100%;
  }
}
.km-tabstrip .km-button {
  font-family: Arial, Helvetica, sans-serif;
  color: #a8a8a8;
  padding: .4em .8em;
  border-width: 0;
  border-color: transparent;
  background: none;
  margin: 0;
  text-align: center;
}
.km-tabstrip .km-button:first-child {
  border-left: 0;
}
.km-tabstrip .km-button:last-child {
  border-right: 0;
}
.km-switch input[type=checkbox] {
  display: none;
}
.km-switch,
.km-checkbox {
  text-align: left;
  font-size: 1rem;
  display: inline-block;
  width: 6.4rem;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  overflow: hidden;
}
.km-switch-wrapper,
.km-slider-wrapper {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.km-switch-background,
.km-slider-background {
  display: block;
  margin: 0 1px 1px -5em;
  height: 100%;
  width: 200%;
}
.km-switch-container {
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-checkbox {
  width: 1.8rem;
  height: 1.8rem;
}
.km-checkbox-checked:after {
  content: "\a0";
  display: block;
  width: 100%;
  height: 100%;
}
.km-switch-handle {
  top: 0;
  left: 0;
  width: 2.72em;
  height: 100%;
  display: inline-block;
  margin: -1px 0 0 -1px;
  background-color: #000;
}
.km-switch-label-on,
.km-switch-label-off {
  display: block;
  width: 130%;
  font-size: 1em;
  line-height: 2em;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
}
.km-switch-label-off {
  left: 104%;
}
.km-switch-label-on {
  left: -134%;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}
.km-list .km-switch {
  position: absolute;
  top: 50%;
  right: .8rem;
  margin-top: -1rem;
}
.km-listview-link:after {
  width: .5rem;
  height: .5rem;
  content: "\a0";
  display: inline-block;
  vertical-align: middle;
  margin-left: -0.2rem;
  border-style: solid;
  border-width: .24rem .24rem 0 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.km-listview-wrapper > ul:not(.km-listview) {
  margin: 0 auto;
}
.km-list,
.km-listview {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.km-listinset,
.km-listgroupinset {
  margin: 1em;
}
.k-ff .km-listinset:after,
.k-ff .km-listgroupinset:after {
  display: block;
  height: 0;
  content: "\a0";
}
.km-listinset,
.km-listgroupinset .km-list {
  overflow: hidden;
}
.km-listview .km-switch {
  margin-top: -0.95rem;
  position: absolute;
  right: .8rem;
  top: 50%;
}
.km-listview .km-list {
  text-indent: 0;
}
.km-list > li,
.km-widget .km-listview-link,
.km-widget .km-listview-label {
  margin: 0;
  display: block;
  position: relative;
  list-style-type: none;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: .5em .7em;
}
.km-list > li {
  line-height: 1.6em;
  overflow: hidden;
}
.km-virtual-list {
  position: relative;
  width: 100%;
  -webkit-transform: translateZ(0);
}
.km-virtual-list > li {
  width: 100%;
  position: absolute;
  top: 0;
  -webkit-transform: translateZ(0);
}
.km-widget.km-list .km-load-more,
.km-widget .km-list .km-load-more {
  border-bottom: 0;
}
.km-list > li > * {
  line-height: normal;
}
.km-group-title {
  display: block;
  font-weight: bold;
  padding: .2em 0;
  text-indent: .8em;
}
.km-listgroupinset .km-group-title {
  margin-top: .65em;
  line-height: 2em;
}
.km-list:not(.km-virtual-list) > li:first-child {
  border-top-width: 0;
}
.km-list:not(.km-virtual-list) > li:last-child {
  border-bottom-width: 0;
}
.km-widget .km-listview-link,
.km-widget .km-listview-label {
  line-height: inherit;
  text-decoration: none;
  margin: -0.5em -0.7em;
}
.km-listview-link:after,
.km-listview-label:after {
  border-color: #777;
  content: "\a0";
  display: block;
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -0.32rem;
}
/* Filtering */
.km-filter-form {
  width: 100%;
  padding: .5em 0;
  border: 1px solid transparent;
  border-width: 1px 0;
  -webkit-transform: translatez(0);
      -ms-transform: translatez(0);
          transform: translatez(0);
}
.km-filter-wrap {
  position: relative;
  margin: 0 .7em;
  padding: .2em .4em;
  border: 1px solid transparent;
}
.km-widget .km-filter-wrap:before {
  display: inline-block;
  vertical-align: middle;
  content: "\e0e9";
  font-size: 1.6em;
  width: 1em;
  height: 1em;
  margin-right: -1em;
  color: inherit;
}
.km-tablet .km-filter-wrap {
  max-width: 24em;
  margin: 0 auto;
}
.km-filter-wrap > input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
.km-filter-wrap input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0;
  background: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  vertical-align: middle;
  padding: 0 1.4em;
}
.km-filter-reset {
  display: inline-block;
  margin-left: -1.6em;
  vertical-align: middle;
  text-align: center;
  z-index: 1;
  text-decoration: none;
  height: 100%;
}
.km-filter-reset .km-clear {
  font-size: 1.6em;
  width: 1em;
  height: 1em;
  display: block;
}
.km-filter-reset > .km-text {
  position: absolute;
  top: -3333px;
  left: -3333px;
}
/* Load more */
.km-load-more {
  display: block;
  padding: .3em 0 1.2em;
  height: 3.2em;
  text-align: center;
}
.km-list .km-listview-item-badge.km-badge,
.km-list .km-listview-link > .km-badge {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 4em;
}
.km-scrollview {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.km-scrollview > div > * {
  -webkit-transform: translatez(0);
}
.km-scrollview > div > [data-role=page] {
  vertical-align: top;
  display: inline-block;
  min-height: 1px;
}
.km-scrollview .km-virtual-page {
  min-height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}
.k-ff18 .km-scrollview > div,
.k-ff19 .km-scrollview > div,
.k-ff20 .km-scrollview > div,
.k-ff21 .km-scrollview > div {
  width: 0;
}
.km-pages {
  text-align: center;
  margin: 0;
  padding: .6em 0 0;
  height: 1.5em;
}
.km-pages li {
  display: inline-block;
  width: .5em;
  height: .55em;
  margin: 0 .3em;
}
/* Forms and icons */
.km-item label:before,
.km-item label.km-item-checked:after {
  position: absolute;
  content: " ";
  display: block;
  top: 50%;
  left: .6em;
  width: 36px;
  height: 36px;
  margin-top: -18px;
}
/* Slider */
.km-widget .k-slider {
  line-height: .6em;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.km-widget .k-slider-horizontal {
  width: 50%;
  height: .6em;
  line-height: .6em;
}
.km-list .k-slider {
  position: absolute;
  right: 0;
  margin-top: -0.5em;
  top: 50%;
}
.km-root .k-slider-track {
  left: 1em !important;
  right: 1em;
  height: 100%;
  display: block;
  position: absolute;
  border: .5em solid transparent;
  border-width: .5em 0;
}
.km-widget .k-slider-horizontal .k-slider-track {
  width: auto !important;
}
.km-widget .k-slider .k-slider-track {
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
.km-widget .k-slider-track,
.km-widget .k-slider-selection {
  margin-top: 0;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}
.km-widget .k-slider-horizontal .k-slider-selection {
  top: 0;
  height: 100%;
}
.km-widget .k-slider-items {
  margin: 0;
}
.km-widget .k-slider .k-draghandle {
  text-indent: -3333px;
  left: 0;
  width: 1.2em;
  height: 1.2em;
  display: block;
  position: absolute;
}
.km-widget .k-slider-vertical .k-draghandle {
  left: -5px;
}
.km-widget .k-slider-tooltip {
  display: none;
}
/* Dialog */
.km-dialog {
  position: absolute;
  min-width: 19em;
  max-width: 25em;
  overflow: hidden;
}
.km-dialog-title {
  position: static;
  float: none;
  height: 2.6em;
  margin-top: -2.6em;
  font-size: 1.22em;
  line-height: 3em;
}
.km-dialog:before {
  content: "\a0";
  display: block;
  overflow: visible;
  width: 100%;
  height: 3em;
  opacity: .2;
}
.km-dialog-content {
  font-weight: normal;
  min-height: 2em;
  text-align: center;
}
.km-dialog .km-button {
  display: block;
  margin: .4em;
  font-size: 1.3em;
  text-align: center;
  padding: .44em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/* Form elements */
.km-list input[type=text]:not(.k-input),
.km-list input[type=password],
.km-list input[type=search],
.km-list input[type=number],
.km-list input[type=tel],
.km-list input[type=url],
.km-list input[type=email],
.km-list input[type=file],
.km-list input[type=month],
.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list select:not([multiple]),
.km-list .k-dropdown,
.km-list textarea {
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1.2em;
  position: absolute;
  top: 50%;
  line-height: normal;
  z-index: 1;
  right: 0;
  margin-top: -1em;
}
.km-widget .k-slider .k-tick,
.km-widget .k-slider .k-label,
.km-widget .k-slider .k-button {
  display: none;
}
.km-list textarea {
  position: relative;
  width: -webkit-calc(50% + .7em);
  width: calc(50% + .7em);
  margin-right: -0.7em;
}
.km-list input,
.km-list select,
.km-list textarea,
.km-list input[type=checkbox],
.km-list input[type=radio] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.km-list input[type=checkbox],
.km-list input[type=radio] {
  position: absolute;
  top: 50%;
  right: .7em;
  margin-top: -0.5em;
  background: none;
}
.km-widget input,
.km-widget textarea {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.km-widget input[readonly],
.km-widget input[type=image],
.km-widget select:not([multiple]) {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.km-list textarea {
  top: 0;
}
.km-list .k-dropdown {
  line-height: 1.4em;
}
.km-list .k-dropdown,
.km-list .k-dropdown .k-input {
  background-color: transparent;
}
.km-list .k-dropdown-wrap {
  display: inline-block;
}
.km-list .km-listview-label:after,
.km-list input ~ .km-listview-link:after,
.km-list textarea ~ .km-listview-link:after,
.km-list select ~ .km-listview-link:after,
.km-list .k-dropdown ~ .km-listview-link:after {
  display: none;
}
.km-list .k-dropdown select,
.km-list .k-dropdown .k-select {
  display: none;
}
.km-widget .km-list textarea {
  position: relative;
  float: right;
  margin-top: 0;
  font-family: inherit;
}
/* Checkboxes and Radios */
.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox] {
  border: 0;
  font-size: inherit;
  width: 1em;
  height: .9em;
}
/* Nova theme features */
.km-list label.km-required:after {
  content: "*";
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.km-list .km-label-above {
  width: 100%;
  display: block;
}
.km-list .km-icon-label:before {
  display: inline-block;
  width: 1em;
  height: 1em;
  font: 1em/1em "Kendo UI";
  margin-right: .89em;
}
.km-list .km-label-above input[type=text]:not(.k-input),
.km-list .km-label-above input[type=password],
.km-list .km-label-above input[type=search],
.km-list .km-label-above input[type=number],
.km-list .km-label-above input[type=tel],
.km-list .km-label-above input[type=url],
.km-list .km-label-above input[type=email],
.km-list .km-label-above input[type=file],
.km-list .km-label-above input[type=month],
.km-list .km-label-above input[type=color],
.km-list .km-label-above input[type=week],
.km-list .km-label-above input[type=date],
.km-list .km-label-above input[type=time],
.km-list .km-label-above input[type=datetime],
.km-list .km-label-above input[type=datetime-local],
.km-list .km-label-above select:not([multiple]),
.km-list .km-label-above .k-dropdown,
.km-list .km-label-above textarea {
  width: 100%;
  right: 0;
  padding-left: 0;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
}
.km-list textarea,
.km-list fieldset input[type=password],
.km-list fieldset input[type=search],
.km-list fieldset input[type=number],
.km-list fieldset input[type=tel],
.km-list fieldset input[type=url],
.km-list fieldset input[type=email],
.km-list fieldset input[type=month],
.km-list fieldset input[type=color],
.km-list fieldset input[type=week],
.km-list fieldset input[type=date],
.km-list fieldset input[type=time],
.km-list fieldset input[type=datetime],
.km-list fieldset input[type=datetime-local],
.km-list fieldset input[type=text]:not(.k-input),
.km-list fieldset select:not([multiple]),
.km-list fieldset .k-dropdown-wrap {
  width: 100%;
  position: relative;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  right: auto;
}
.km-list fieldset textarea {
  width: 100%;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  margin-right: 0;
  padding-left: 0;
}
.km-list .km-legend-button {
  display: block;
  text-decoration: none;
}
.km-list .km-inline-field {
  display: inline-block;
  position: relative;
  width: -webkit-calc(48.39%);
  width: calc(48.39%);
  overflow: hidden;
  white-space: nowrap;
}
.km-list .km-inline-field input[type=text]:not(.k-input),
.km-list .km-inline-field input[type=password],
.km-list .km-inline-field input[type=search],
.km-list .km-inline-field input[type=number],
.km-list .km-inline-field input[type=tel],
.km-list .km-inline-field input[type=url],
.km-list .km-inline-field input[type=email],
.km-list .km-inline-field input[type=file],
.km-list .km-inline-field input[type=month],
.km-list .km-inline-field input[type=color],
.km-list .km-inline-field input[type=week],
.km-list .km-inline-field input[type=date],
.km-list .km-inline-field input[type=time],
.km-list .km-inline-field input[type=datetime],
.km-list .km-inline-field input[type=datetime-local],
.km-list .km-inline-field select:not([multiple]),
.km-list .km-inline-field .k-dropdown,
.km-list .km-inline-field textarea {
  position: absolute;
  width: 100%;
  left: 0;
}
.km-list .km-full-width-slider {
  position: relative;
  width: auto;
  display: block;
}
/* Standalone Checkboxes and RadioButtons */
.km-root input.km-checkbox,
.km-root input.km-radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  height: 0;
}
.km-root .km-checkbox-label,
.km-root .km-radio-label {
  display: block;
  position: relative;
  vertical-align: middle;
}
.km-root .km-checkbox-label:before,
.km-root .km-checkbox-label:after {
  content: "";
  position: absolute;
  top: 0;
}
.km-root input.km-checkbox:checked + .km-checkbox-label:after {
  content: "\e227";
  font-family: "Kendo UI";
}
.km-root .km-radio-label {
  position: relative;
  vertical-align: middle;
}
.km-root .km-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}
.km-root input.km-radio:checked + .km-radio-label:after {
  content: "";
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 0.25rem;
  border-radius: 50%;
}
/* Validation */
.km-root .km-invalid-msg {
  font-size: 1.2rem;
  position: static;
  display: inline-block;
  border-width: 1px;
  padding-top: 12px;
  padding-bottom: 11px;
  width: 100%;
  text-align: center;
}
.km-root .km-icon-label.k-i-arrow-e:before {
  content: "\E000";
}
.km-root .km-icon-label.k-i-arrow-n:before {
  content: "\E001";
}
.km-root .km-icon-label.k-i-arrow-s:before {
  content: "\E002";
}
.km-root .km-icon-label.k-i-arrow-w:before {
  content: "\E003";
}
.km-root .km-icon-label.k-i-arrow-ne:before {
  content: "\E004";
}
.km-root .km-icon-label.k-i-arrow-nw:before {
  content: "\E005";
}
.km-root .km-icon-label.k-i-arrow-se:before {
  content: "\E006";
}
.km-root .km-icon-label.k-i-arrow-sw:before {
  content: "\E007";
}
.km-root .km-icon-label.k-i-seek-e:before {
  content: "\E008";
}
.km-root .km-icon-label.k-i-seek-n:before {
  content: "\E009";
}
.km-root .km-icon-label.k-i-seek-s:before {
  content: "\E00A";
}
.km-root .km-icon-label.k-i-seek-w:before {
  content: "\E00B";
}
.km-root .km-icon-label.k-si-arrow-e:before {
  content: "\E00C";
}
.km-root .km-icon-label.k-si-arrow-n:before {
  content: "\E00D";
}
.km-root .km-icon-label.k-si-arrow-s:before {
  content: "\E00E";
}
.km-root .km-icon-label.k-si-arrow-w:before {
  content: "\E00F";
}
.km-root .km-icon-label.k-i-arrowhead-e:before {
  content: "\E010";
}
.km-root .km-icon-label.k-i-arrowhead-n:before {
  content: "\E011";
}
.km-root .km-icon-label.k-i-arrowhead-s:before {
  content: "\E012";
}
.km-root .km-icon-label.k-i-arrowhead-w:before {
  content: "\E013";
}
.km-root .km-icon-label.k-i-arrowhead-ew:before {
  content: "\E014";
}
.km-root .km-icon-label.k-i-arrowhead-ns:before {
  content: "\E015";
}
.km-root .km-icon-label.k-i-move:before {
  content: "\E016";
}
.km-root .km-icon-label.k-i-resize:before {
  content: "\E017";
}
.km-root .km-icon-label.k-i-resize-45:before {
  content: "\E018";
}
.km-root .km-icon-label.k-i-resize-135:before {
  content: "\E019";
}
.km-root .km-icon-label.k-i-resize-h:before {
  content: "\E01A";
}
.km-root .km-icon-label.k-i-resize-v:before {
  content: "\E01B";
}
.km-root .km-icon-label.k-i-refresh:before {
  content: "\E01C";
}
.km-root .km-icon-label.k-i-refresh-rev:before {
  content: "\E01D";
}
.km-root .km-icon-label.k-si-refresh:before {
  content: "\E01E";
}
.km-root .km-icon-label.k-si-refresh-rev:before {
  content: "\E01F";
}
.km-root .km-icon-label.k-i-turn-en:before {
  content: "\E020";
}
.km-root .km-icon-label.k-i-turn-es:before {
  content: "\E021";
}
.km-root .km-icon-label.k-i-turn-ne:before {
  content: "\E022";
}
.km-root .km-icon-label.k-i-turn-nw:before {
  content: "\E023";
}
.km-root .km-icon-label.k-i-turn-se:before {
  content: "\E024";
}
.km-root .km-icon-label.k-i-turn-sw:before {
  content: "\E025";
}
.km-root .km-icon-label.k-i-turn-wn:before {
  content: "\E026";
}
.km-root .km-icon-label.k-i-turn-ws:before {
  content: "\E027";
}
.km-root .km-icon-label.k-i-uturn-e:before {
  content: "\E028";
}
.km-root .km-icon-label.k-i-uturn-e-rev:before {
  content: "\E029";
}
.km-root .km-icon-label.k-i-uturn-n:before {
  content: "\E02A";
}
.km-root .km-icon-label.k-i-uturn-n-rev:before {
  content: "\E02B";
}
.km-root .km-icon-label.k-i-uturn-s:before {
  content: "\E02C";
}
.km-root .km-icon-label.k-i-uturn-s-rev:before {
  content: "\E02D";
}
.km-root .km-icon-label.k-i-uturn-w:before {
  content: "\E02E";
}
.km-root .km-icon-label.k-i-uturn-w-rev:before {
  content: "\E02F";
}
.km-root .km-icon-label.k-i-tick-sign:before {
  content: "\E030";
}
.km-root .km-icon-label.k-i-close-sign:before {
  content: "\E031";
}
.km-root .km-icon-label.k-i-plus-sign:before {
  content: "\E032";
}
.km-root .km-icon-label.k-i-minus-sign:before {
  content: "\E033";
}
.km-root .km-icon-label.k-si-plus-sign:before {
  content: "\E034";
}
.km-root .km-icon-label.k-si-minus-sign:before {
  content: "\E035";
}
.km-root .km-icon-label.k-i-cancel:before {
  content: "\E036";
}
.km-root .km-icon-label.k-i-tick:before {
  content: "\E037";
}
.km-root .km-icon-label.k-i-close:before {
  content: "\E038";
}
.km-root .km-icon-label.k-i-plus:before {
  content: "\E039";
}
.km-root .km-icon-label.k-i-minus:before {
  content: "\E03A";
}
.km-root .km-icon-label.k-si-plus:before {
  content: "\E03B";
}
.km-root .km-icon-label.k-si-minus:before {
  content: "\E03C";
}
.km-root .km-icon-label.k-i-stop:before {
  content: "\E03D";
}
.km-root .km-icon-label.k-i-pause:before {
  content: "\E03E";
}
.km-root .km-icon-label.k-i-eject:before {
  content: "\E03F";
}
.km-root .km-icon-label.k-i-volume-off:before {
  content: "\E040";
}
.km-root .km-icon-label.k-i-volume-low:before {
  content: "\E041";
}
.km-root .km-icon-label.k-i-volume-high:before {
  content: "\E042";
}
.km-root .km-icon-label.k-i-earphones:before {
  content: "\E043";
}
.km-root .km-icon-label.k-i-heart:before {
  content: "\E044";
}
.km-root .km-icon-label.k-i-heart-empty:before {
  content: "\E045";
}
.km-root .km-icon-label.k-i-heart-half:before {
  content: "\E046";
}
.km-root .km-icon-label.k-i-star:before {
  content: "\E047";
}
.km-root .km-icon-label.k-i-star-empty:before {
  content: "\E048";
}
.km-root .km-icon-label.k-i-star-half:before {
  content: "\E049";
}
.km-root .km-icon-label.k-i-star-half-empty:before {
  content: "\E04A";
}
.km-root .km-icon-label.k-i-chart-column:before {
  content: "\E04B";
}
.km-root .km-icon-label.k-i-chart-bar:before {
  content: "\E04C";
}
.km-root .km-icon-label.k-i-chart-pie:before {
  content: "\E04D";
}
.km-root .km-icon-label.k-i-chart-donut:before {
  content: "\E04E";
}
.km-root .km-icon-label.k-i-chart-line:before {
  content: "\E04F";
}
.km-root .km-icon-label.k-i-chart-area:before {
  content: "\E050";
}
.km-root .km-icon-label.k-i-chart-donut:before {
  content: "\E051";
}
.km-root .km-icon-label.k-i-align-left:before {
  content: "\E052";
}
.km-root .km-icon-label.k-i-align-center:before {
  content: "\E053";
}
.km-root .km-icon-label.k-i-align-right:before {
  content: "\E054";
}
.km-root .km-icon-label.k-i-align-justify:before {
  content: "\E055";
}
.km-root .km-icon-label.k-i-align-clear:before {
  content: "\E056";
}
.km-root .km-icon-label.k-i-bold:before {
  content: "\E057";
}
.km-root .km-icon-label.k-i-bold-sans:before {
  content: "\E058";
}
.km-root .km-icon-label.k-i-italic:before {
  content: "\E059";
}
.km-root .km-icon-label.k-i-italic-sans:before {
  content: "\E05A";
}
.km-root .km-icon-label.k-i-underline:before {
  content: "\E05B";
}
.km-root .km-icon-label.k-i-underline-sans:before {
  content: "\E05C";
}
.km-root .km-icon-label.k-i-strikethrough:before {
  content: "\E05D";
}
.km-root .km-icon-label.k-i-strikethrough-sans:before {
  content: "\E05E";
}
.km-root .km-icon-label.k-i-font-a:before {
  content: "\E05F";
}
.km-root .km-icon-label.k-i-font-a-sans:before {
  content: "\E060";
}
.km-root .km-icon-label.k-i-font-t:before {
  content: "\E061";
}
.km-root .km-icon-label.k-i-font-t-sans:before {
  content: "\E062";
}
.km-root .km-icon-label.k-i-brightness:before {
  content: "\E063";
}
.km-root .km-icon-label.k-i-brightness-contrast:before {
  content: "\E064";
}
.km-root .km-icon-label.k-i-contrast:before {
  content: "\E065";
}
.km-root .km-icon-label.k-i-crop:before {
  content: "\E066";
}
.km-root .km-icon-label.k-i-mirror:before {
  content: "\E067";
}
.km-root .km-icon-label.k-i-flip-h:before {
  content: "\E068";
}
.km-root .km-icon-label.k-i-flip-v:before {
  content: "\E069";
}
.km-root .km-icon-label.k-i-rotate:before {
  content: "\E06A";
}
.km-root .km-icon-label.k-i-rotate-rev:before {
  content: "\E06B";
}
.km-root .km-icon-label.k-i-filter:before {
  content: "\E06C";
}
.km-root .km-icon-label.k-i-filter-clear:before {
  content: "\E06D";
}
.km-root .km-icon-label.k-i-sort-asc:before {
  content: "\E06E";
}
.km-root .km-icon-label.k-i-sort-desc:before {
  content: "\E06F";
}
.km-root .km-icon-label.k-i-sort-clear:before {
  content: "\E070";
}
.km-root .km-icon-label.k-i-indent:before {
  content: "\E071";
}
.km-root .km-icon-label.k-i-outdent:before {
  content: "\E072";
}
.km-root .km-icon-label.k-i-hyperlink:before {
  content: "\E073";
}
.km-root .km-icon-label.k-i-hyperlink-clear:before {
  content: "\E074";
}
.km-root .km-icon-label.k-i-hyperlink-ext:before {
  content: "\E075";
}
.km-root .km-icon-label.k-si-hyperlink-ext:before {
  content: "\E076";
}
.km-root .km-icon-label.k-i-ul:before {
  content: "\E077";
}
.km-root .km-icon-label.k-si-ul:before {
  content: "\E078";
}
.km-root .km-icon-label.k-i-paint:before {
  content: "\E079";
}
.km-root .km-icon-label.k-i-paste:before {
  content: "\E07A";
}
.km-root .km-icon-label.k-i-pencil:before {
  content: "\E07B";
}
.km-root .km-icon-label.k-i-image:before {
  content: "\E07C";
}
.km-root .km-icon-label.k-i-image-add:before {
  content: "\E07D";
}
.km-root .km-icon-label.k-i-print:before {
  content: "\E07E";
}
.km-root .km-icon-label.k-i-zoom:before {
  content: "\E07F";
}
.km-root .km-icon-label.k-i-zoom-in:before {
  content: "\E080";
}
.km-root .km-icon-label.k-i-zoom-out:before {
  content: "\E081";
}
.km-root .km-icon-label.k-i-asterisk:before {
  content: "\E082";
}
.km-root .km-icon-label.k-i-clip:before {
  content: "\E083";
}
.km-root .km-icon-label.k-i-clip-45:before {
  content: "\E084";
}
.km-root .km-icon-label.k-i-qrcode:before {
  content: "\E085";
}
.km-root .km-icon-label.k-i-book:before {
  content: "\E086";
}
.km-root .km-icon-label.k-i-bookmark:before {
  content: "\E087";
}
.km-root .km-icon-label.k-i-briefcase:before {
  content: "\E088";
}
.km-root .km-icon-label.k-i-calendar:before {
  content: "\E089";
}
.km-root .km-icon-label.k-i-camera-still:before {
  content: "\E08A";
}
.km-root .km-icon-label.k-i-camera-video:before {
  content: "\E08B";
}
.km-root .km-icon-label.k-i-certificate:before {
  content: "\E08C";
}
.km-root .km-icon-label.k-i-clock:before {
  content: "\E08D";
}
.km-root .km-icon-label.k-i-cloud:before {
  content: "\E08E";
}
.km-root .km-icon-label.k-i-collapse:before {
  content: "\E08F";
}
.km-root .km-icon-label.k-i-columns:before {
  content: "\E090";
}
.km-root .km-icon-label.k-i-comment:before {
  content: "\E091";
}
.km-root .km-icon-label.k-i-comment-empty:before {
  content: "\E092";
}
.km-root .km-icon-label.k-i-comments:before {
  content: "\E093";
}
.km-root .km-icon-label.k-i-comments-empty:before {
  content: "\E094";
}
.km-root .km-icon-label.k-i-credit-card:before {
  content: "\E095";
}
.km-root .km-icon-label.k-i-download:before {
  content: "\E096";
}
.km-root .km-icon-label.k-i-draghandle:before {
  content: "\E097";
}
.km-root .km-icon-label.k-si-draghandle:before {
  content: "\E098";
}
.km-root .km-icon-label.k-i-envelop:before {
  content: "\E099";
}
.km-root .km-icon-label.k-i-envelop-open:before {
  content: "\E09A";
}
.km-root .km-icon-label.k-i-eye:before {
  content: "\E09B";
}
.km-root .km-icon-label.k-i-file:before {
  content: "\E09C";
}
.km-root .km-icon-label.k-i-file-add:before {
  content: "\E09D";
}
.km-root .km-icon-label.k-i-film:before {
  content: "\E09E";
}
.km-root .km-icon-label.k-i-flag:before {
  content: "\E09F";
}
.km-root .km-icon-label.k-i-folder-add:before {
  content: "\E0A0";
}
.km-root .km-icon-label.k-i-folder:before {
  content: "\E0A1";
}
.km-root .km-icon-label.k-i-folder-open:before {
  content: "\E0A2";
}
.km-root .km-icon-label.k-i-folder-up:before {
  content: "\E0A3";
}
.km-root .km-icon-label.k-i-gear:before {
  content: "\E0A4";
}
.km-root .km-icon-label.k-si-gear:before {
  content: "\E0A5";
}
.km-root .km-icon-label.k-i-transmit:before {
  content: "\E0A6";
}
.km-root .km-icon-label.k-i-beer:before {
  content: "\E0A7";
}
.km-root .km-icon-label.k-i-cocktail:before {
  content: "\E0A8";
}
.km-root .km-icon-label.k-i-coffee:before {
  content: "\E0A9";
}
.km-root .km-icon-label.k-i-wine:before {
  content: "\E0AA";
}
.km-root .km-icon-label.k-i-grid:before {
  content: "\E0AB";
}
.km-root .km-icon-label.k-i-thumbs:before {
  content: "\E0AC";
}
.km-root .km-icon-label.k-i-split-h:before {
  content: "\E0AD";
}
.km-root .km-icon-label.k-i-split-v:before {
  content: "\E0AE";
}
.km-root .km-icon-label.k-i-home:before {
  content: "\E0AF";
}
.km-root .km-icon-label.k-i-inbox:before {
  content: "\E0B0";
}
.km-root .km-icon-label.k-i-key:before {
  content: "\E0B1";
}
.km-root .km-icon-label.k-i-login:before {
  content: "\E0B2";
}
.km-root .km-icon-label.k-i-logout:before {
  content: "\E0B3";
}
.km-root .km-icon-label.k-i-place:before {
  content: "\E0B4";
}
.km-root .km-icon-label.k-i-megaphone:before {
  content: "\E0B5";
}
.km-root .km-icon-label.k-i-note:before {
  content: "\E0B6";
}
.km-root .km-icon-label.k-i-pin:before {
  content: "\E0B7";
}
.km-root .km-icon-label.k-i-unpin:before {
  content: "\E0B8";
}
.km-root .km-icon-label.k-i-power:before {
  content: "\E0B9";
}
.km-root .km-icon-label.k-i-progress-bars:before {
  content: "\E0BA";
}
.km-root .km-icon-label.k-i-road:before {
  content: "\E0BB";
}
.km-root .km-icon-label.k-i-rss:before {
  content: "\E0BC";
}
.km-root .km-icon-label.k-i-floppy:before {
  content: "\E0BD";
}
.km-root .km-icon-label.k-i-sitemap:before {
  content: "\E0BE";
}
.km-root .km-icon-label.k-i-tag-45:before {
  content: "\E0BF";
}
.km-root .km-icon-label.k-i-tag-h:before {
  content: "\E0C0";
}
.km-root .km-icon-label.k-i-thunderbolt:before {
  content: "\E0C1";
}
.km-root .km-icon-label.k-i-tooltip:before {
  content: "\E0C2";
}
.km-root .km-icon-label.k-i-trash:before {
  content: "\E0C3";
}
.km-root .km-icon-label.k-i-trophy:before {
  content: "\E0C4";
}
.km-root .km-icon-label.k-i-ungroup:before {
  content: "\E0C5";
}
.km-root .km-icon-label.k-i-upload:before {
  content: "\E0C6";
}
.km-root .km-icon-label.k-i-window:before {
  content: "\E0C7";
}
.km-root .km-icon-label.k-i-tiles:before {
  content: "\E0C8";
}
.km-root .km-icon-label.k-i-wrench:before {
  content: "\E0C9";
}
.km-root .km-icon-label.k-i-action:before {
  content: "\E0CA";
}
.km-root .km-icon-label.k-i-add:before {
  content: "\E0CB";
}
.km-root .km-icon-label.k-i-add-inv:before {
  content: "\E0CC";
}
.km-root .km-icon-label.k-i-armchair:before {
  content: "\E0CD";
}
.km-root .km-icon-label.k-i-battery:before {
  content: "\E0CE";
}
.km-root .km-icon-label.k-i-book-open:before {
  content: "\E0CF";
}
.km-root .km-icon-label.k-i-camera:before {
  content: "\E0D0";
}
.km-root .km-icon-label.k-i-cart:before {
  content: "\E0D1";
}
.km-root .km-icon-label.k-i-time:before {
  content: "\E0D2";
}
.km-root .km-icon-label.k-i-coffee-card:before {
  content: "\E0D3";
}
.km-root .km-icon-label.k-i-download-arrow:before {
  content: "\E0D4";
}
.km-root .km-icon-label.k-i-edit:before {
  content: "\E0D5";
}
.km-root .km-icon-label.k-i-faves:before {
  content: "\E0D6";
}
.km-root .km-icon-label.k-i-star:before {
  content: "\E0D7";
}
.km-root .km-icon-label.k-i-featured:before {
  content: "\E0D8";
}
.km-root .km-icon-label.k-i-forward:before {
  content: "\E0D9";
}
.km-root .km-icon-label.k-i-cog:before {
  content: "\E0DA";
}
.km-root .km-icon-label.k-i-globe:before {
  content: "\E0DB";
}
.km-root .km-icon-label.k-i-globe-inv:before {
  content: "\E0DC";
}
.km-root .km-icon-label.k-i-house:before {
  content: "\E0DD";
}
.km-root .km-icon-label.k-i-info:before {
  content: "\E0DE";
}
.km-root .km-icon-label.k-i-share:before {
  content: "\E0DF";
}
.km-root .km-icon-label.k-i-more-h:before {
  content: "\E0E0";
}
.km-root .km-icon-label.k-i-more-v:before {
  content: "\E0E1";
}
.km-root .km-icon-label.k-i-next:before {
  content: "\E0E2";
}
.km-root .km-icon-label.k-i-pause-a:before {
  content: "\E0E3";
}
.km-root .km-icon-label.k-i-user:before {
  content: "\E0E4";
}
.km-root .km-icon-label.k-i-play-a:before {
  content: "\E0E5";
}
.km-root .km-icon-label.k-i-refresh-a:before {
  content: "\E0E6";
}
.km-root .km-icon-label.k-i-reset:before {
  content: "\E0E7";
}
.km-root .km-icon-label.k-i-rewind:before {
  content: "\E0E8";
}
.km-root .km-icon-label.k-i-search-a:before {
  content: "\E0E9";
}
.km-root .km-icon-label.k-i-stop-a:before {
  content: "\E0EA";
}
.km-root .km-icon-label.k-i-tiles-a:before {
  content: "\E0EB";
}
.km-root .km-icon-label.k-i-trash-a:before {
  content: "\E0EC";
}
.km-root .km-icon-label.k-i-undo:before {
  content: "\E0ED";
}
.km-root .km-icon-label.k-i-redo:before {
  content: "\E0EE";
}
.km-root .km-icon-label.k-i-volume-a:before {
  content: "\E0EF";
}
.km-root .km-icon-label.k-i-wifi:before {
  content: "\E0F0";
}
.km-root .km-icon-label.k-i-more-lines:before {
  content: "\E0F1";
}
.km-root .km-icon-label.k-i-pull-to-ref:before {
  content: "\E0F2";
}
.km-root .km-icon-label.k-i-loading-android:before {
  content: "\E0F3";
}
.km-root .km-icon-label.k-i-loading-blackberry:before {
  content: "\E0F4";
}
.km-root .km-icon-label.k-i-loading-meego:before {
  content: "\E0F5";
}
.km-root .km-icon-label.k-i-loading-custom:before {
  content: "\E0F6";
}
.km-root .km-icon-label.k-i-loading-ios:before {
  content: "\E0F7";
}
.km-root .km-icon-label.k-i-bug:before {
  content: "\E0F8";
}
.km-root .km-icon-label.k-i-info:before {
  content: "\E0F9";
}
.km-root .km-icon-label.k-i-warning:before {
  content: "\E0FA";
}
.km-root .km-icon-label.k-i-question:before {
  content: "\E0FB";
}
.km-root .km-icon-label.k-i-insert-n:before {
  content: "\E0FC";
}
.km-root .km-icon-label.k-i-insert-m:before {
  content: "\E0FD";
}
.km-root .km-icon-label.k-i-insert-s:before {
  content: "\E0FE";
}
.km-root .km-icon-label.k-i-lock:before {
  content: "\E0FF";
}
.km-root .km-icon-label.k-i-unlock:before {
  content: "\E100";
}
.km-root .km-icon-label.k-i-phone:before {
  content: "\E101";
}
.km-root .km-icon-label.k-i-tablet:before {
  content: "\E102";
}
.km-root .km-icon-label.k-i-ol:before {
  content: "\E103";
}
.km-root .km-icon-label.k-i-barcode:before {
  content: "\E104";
}
.km-root .km-icon-label.k-i-html5:before {
  content: "\E105";
}
.km-root .km-icon-label.k-i-css3:before {
  content: "\E106";
}
.km-root .km-icon-label.k-i-kendoui:before {
  content: "\E107";
}
.km-root .km-icon-label.k-i-telerik:before {
  content: "\E108";
}
.km-root .km-icon-label.k-i-icenium:before {
  content: "\E109";
}
.km-root .km-icon-label.k-i-sitefinity:before {
  content: "\E10A";
}
.km-root .km-icon-label.k-i-twitter:before {
  content: "\E10B";
}
.km-root .km-icon-label.k-i-linkedin:before {
  content: "\E10C";
}
.km-root .km-icon-label.k-i-facebook:before {
  content: "\E10D";
}
.km-root .km-icon-label.k-i-pinterest:before {
  content: "\E10E";
}
.km-root .km-icon-label.k-i-youtube:before {
  content: "\E10F";
}
.km-root .km-icon-label.k-i-vimeo:before {
  content: "\E110";
}
.km-root .km-icon-label.k-i-behance:before {
  content: "\E111";
}
.km-root .km-icon-label.k-i-dribbble:before {
  content: "\E112";
}
.km-root .km-icon-label.k-i-googleplus:before {
  content: "\E113";
}
.km-root .km-icon-label.k-i-minimize:before {
  content: "\E114";
}
.km-root .km-icon-label.k-i-html:before {
  content: "\E115";
}
.km-root .km-icon-label.k-i-group:before {
  content: "\E116";
}
.km-root .km-icon-label.k-i-subscript:before {
  content: "\E117";
}
.km-root .km-icon-label.k-i-superscript:before {
  content: "\E118";
}
.km-root .km-icon-label.k-i-drophere:before {
  content: "\E119";
}
/* PopUp + ActionSheet */
.km-root .km-popup .k-item,
.km-widget.km-actionsheet > li {
  list-style-type: none;
  padding: inherit 1em;
  border-bottom: 1px solid #555;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-widget.km-actionsheet > li {
  line-height: 2em;
  border-bottom: 0;
}
.km-widget.km-actionsheet > li > a {
  line-height: 1.5em;
  text-align: left;
  background: transparent;
}
.km-root .km-popup .k-list,
.km-widget.km-actionsheet {
  padding: 0;
  margin: 0;
}
.km-root .km-popup .k-item:last-child,
.km-widget.km-actionsheet > li:last-child {
  border: 0;
}
.km-widget.km-actionsheet-wrapper {
  width: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
}
.km-actionsheet-root.km-shim .k-animation-container {
  width: 100% !important;
  height: 100% !important;
}
.km-tablet .km-pane div.km-actionsheet-phone {
  background: transparent;
}
.km-tablet .km-actionsheet-phone li.km-actionsheet-title,
.km-tablet div.km-actionsheet-phone li.km-actionsheet-cancel {
  display: block;
}
/* PopOver */
.km-popover-root .km-popup-wrapper {
  position: relative !important;
}
.km-popup-wrapper,
.km-modalview-wrapper {
  z-index: 10001;
  position: relative;
  background: none;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10002;
}
.km-popup-arrow,
.km-popup-arrow:after,
.km-popup-arrow:before {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  left: 0;
  z-index: 2;
}
.km-left .km-popup-arrow,
.km-right .km-popup-arrow {
  margin-top: -8px;
}
.km-up .km-popup-arrow,
.km-down .km-popup-arrow {
  margin-left: -8px;
}
.km-popup-arrow:after,
.km-popup-arrow:before {
  display: block;
  content: "\a0";
  width: 0;
  height: 0;
}
.km-up .km-popup-arrow {
  top: auto;
  bottom: 0;
}
.km-left .km-popup-arrow {
  left: auto;
  right: 0;
}
.km-popup.km-pane {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background: transparent;
}
.km-popover-root .km-view {
  position: relative;
}
.km-popover-root .km-content {
  -ms-flex: auto;
}
/* Drawer */
.km-drawer,
[data-role=drawer] {
  top: 0;
  left: auto;
  width: 250px;
}
.km-drawer .km-header,
.km-drawer .km-footer {
  z-index: 0;
}
.km-left-drawer {
  left: 0;
}
.km-right-drawer {
  right: 0;
}
/* ModalView */
.km-modalview-root {
  text-align: center;
}
.km-modalview-root > .k-animation-container {
  text-align: left;
  position: relative !important;
  top: auto !important;
  left: auto !important;
  display: inline-block !important;
  vertical-align: middle;
}
.km-modalview,
.km-modalview-wrapper:before {
  overflow: hidden;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  max-height: 100%;
}
.km-modalview .km-content {
  box-flex: 1;
}
.km-auto-height .km-content {
  -ms-flex: auto;
}
.km-native-scrolling .km-view.km-modalview {
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.km-modalview-root:before,
.km-modalview-wrapper:before {
  vertical-align: middle;
  height: 100%;
  margin-left: -1px;
  content: "\a0";
  width: 0px;
  display: inline-block;
}
/* Pull to refresh */
.km-scroller-pull {
  width: 100%;
  display: block;
  position: absolute;
  line-height: 3em;
  font-size: 1.4em;
  text-align: center;
  -webkit-transform: translate3d(0, -3em, 0);
          transform: translate3d(0, -3em, 0);
}
.km-scroller-pull .km-template {
  display: inline-block;
  min-width: 200px;
  text-align: left;
}
.km-load-more .km-icon,
.km-widget .km-scroller-pull .km-icon {
  display: inline-block;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
  width: 2rem;
  font-size: 2rem;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 300ms linear;
          transition: transform 300ms linear;
}
.km-widget .km-scroller-release .km-icon {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.km-widget .km-scroller-refresh .km-icon {
  -webkit-transition: none;
          transition: none;
}
/* Scroller */
.km-touch-scrollbar {
  position: absolute;
  visibility: hidden;
  z-index: 200000;
  height: .4em;
  width: .4em;
  background-color: #333;
  opacity: 0;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: opacity 0.3s linear;
          transition: opacity 0.3s linear;
}
.km-vertical-scrollbar {
  height: 100%;
  right: 2px;
  top: 0;
}
.km-horizontal-scrollbar {
  width: 100%;
  left: 0;
  bottom: 2px;
}
.km-scrollview,
.km-scroll-container {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -moz-user-select: -moz-none;
  -webkit-margin-collapse: separate;
  margin-collapse: separate;
}
.km-scroll-wrapper {
  position: relative;
}
.km-scroll-header {
  position: absolute;
  z-index: 1001;
  width: 100%;
  top: 0;
  left: 0;
}
/* SplitView */
div.km-splitview > .km-content,
kendo-mobile-split-view.km-splitview > .km-content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
div.km-split-vertical > .km-content,
kendo-mobile-split-view.km-split-vertical > .km-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
div.km-split-content > .km-pane {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
      -ms-flex: 2;
          flex: 2;
  width: auto;
  height: auto;
}
div.km-split-content > .km-pane:first-child {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
div.km-split-horizontal > .km-content > .km-pane {
  top: 0;
  bottom: 0;
}
.km-split-vertical > .km-content > .km-pane > .km-view {
  display: -webkit-box;
}
/* animation classes */
.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}
.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}
.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}
/* Zoom */
.k-fx-zoom.k-fx-start .k-fx-next {
  -webkit-transform: scale(0) !important;
      -ms-transform: scale(0) !important;
          transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: scale(0) !important;
      -ms-transform: scale(0) !important;
          transform: scale(0) !important;
}
/* Fade */
.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}
/* Slide */
.k-fx-slide {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-current .km-footer {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right {
  /* right reverse */
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Overlay */
.k-fx.k-fx-overlay.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  -webkit-transform: translatey(100%);
      -ms-transform: translatey(100%);
          transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  -webkit-transform: translatey(-100%);
      -ms-transform: translatey(-100%);
          transform: translatey(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  -webkit-transform: translatey(100%);
      -ms-transform: translatey(100%);
          transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  -webkit-transform: translatey(-100%);
      -ms-transform: translatey(-100%);
          transform: translatey(-100%);
}
/* Platform specific workarounds */
.km-on-wp .km-view,
.km-on-wp .km-header,
.km-on-wp .km-footer,
.km-on-wp .km-slider,
.km-on-wp .km-switch,
.km-on-wp .km-filter-reset,
.km-on-wp .km-shim .k-animation-container,
.km-on-wp .km-scroll-container {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.km-ios,
.km-blackberry,
.km-on-ios .km-view,
.km-on-ios .km-header,
.km-on-ios .km-footer,
.km-on-ios .km-slider,
.km-on-ios .km-switch,
.km-on-ios .km-group-title,
.km-on-ios .km-filter-reset,
.km-on-ios .km-shim .k-animation-container,
.km-on-ios .km-scroll-container,
.km-on-blackberry .km-view,
.km-on-blackberry .km-content,
.km-on-blackberry .km-header,
.km-on-blackberry .km-footer,
.km-on-blackberry .km-icon,
.km-on-blackberry .km-switch,
.km-on-blackberry .km-popup .k-item,
.km-on-blackberry .km-actionsheet-wrapper,
.km-on-android.km-4 .k-slider {
  -webkit-transform: translatez(0);
}
.km-on-android.km-4 .km-switch,
.km-on-android.km-4 .km-listview-wrapper,
.km-on-android.km-4 .km-content,
.km-on-android.km-4 .km-switch-handle,
.km-android.km-4.km-on-android .km-switch-wrapper,
.km-on-android.km-4 .km-scroll-container,
.km-on-meego .km-content,
.km-on-meego .km-switch,
.km-on-meego .km-icon,
.km-on-meego .km-header,
.km-on-meego .km-footer,
.km-on-meego .km-content,
.km-on-meego .km-switch-handle,
.km-on-meego .km-switch-wrapper {
  -webkit-transform: translatez(0);
  -webkit-backface-visibility: hidden;
}
.km-android4.km-ios-chrome .km-listview-wrapper {
  -webkit-transform: none;
}
.km-native-scrolling .km-header,
.km-native-scrolling .km-footer,
.km-native-scrolling .km-shim,
.km-native-scrolling .km-popup-overlay,
.km-native-scrolling .km-drawer,
.km-native-scrolling > .km-pane > .km-loader,
.km-on-android.km-4 .km-scroller-pull .km-icon {
  -webkit-backface-visibility: hidden;
}
.km-on-android.km-4 input {
  -webkit-user-modify: read-write-plaintext-only;
}
.km-wp .km-view .km-absolute,
.km-meego .km-view .km-absolute {
  position: absolute;
}
.km-widget {
  font-size: 1em;
}
.km-root .km-pane,
.km-root .km-view,
.km-root .km-slider,
.km-root .km-switch,
.km-root .km-group-title,
.km-root .km-filter-reset,
.km-root .km-shim .k-animation-container {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.km-widget,
.km-widget * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
.km-toolbar,
.km-navbar,
.km-tabstrip,
.km-tabstrip .km-button {
  border-radius: 0;
}
.km-touch-scrollbar {
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 1em;
}
.km-popup .k-popup {
  font-size: 1em !important;
}
.km-popup-wrapper.k-popup {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
}
.km-popup .k-item,
.km-actionsheet > li > a {
  text-decoration: none;
  padding: .5em 0 .5em .6em;
  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-actionsheet-wrapper,
.km-popup .k-list-container {
  bottom: 0;
  border: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-popup {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-popup .k-list-container {
  width: 100% !important;
  height: auto !important;
  max-height: 80%;
}
.km-shim .k-animation-container {
  left: 0;
  top: auto;
  bottom: 0;
}
/* Loader & Pull-to-refresh */
.km-loader:before,
.km-scroller-refresh.km-load-more,
.km-scroller-pull {
  border-radius: 20em;
  overflow: visible;
}
.km-loader:before {
  content: "\a0";
  display: block;
  position: absolute;
  margin-top: -2em;
  margin-left: -2em;
  width: 4em;
  height: 4em;
  top: 50%;
  left: 50%;
  border-radius: 5em;
}
.km-loader {
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.km-loader h1 {
  display: none;
  font-size: 1em;
  position: absolute;
  left: -50%;
  width: 200%;
  top: 55%;
}
.km-loading,
.km-loader .km-loading-left,
.km-loader .km-loading-right,
.km-load-more.km-scroller-refresh .km-icon,
.km-scroller-pull.km-scroller-refresh .km-icon,
.km-scroller-refresh .km-loading-left,
.km-scroller-refresh .km-loading-right {
  font-size: 1em;
  display: block;
  width: .36em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.8em;
  margin-top: -0.5em;
  border-radius: 1em;
  -webkit-animation: km-webload 0.6s infinite linear;
          animation: km-webload 0.6s infinite linear;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
.km-load-more.km-scroller-refresh .km-icon,
.km-scroller-pull.km-scroller-refresh .km-icon,
.km-scroller-refresh .km-loading-left,
.km-scroller-refresh .km-loading-right {
  height: .6em;
  margin-top: -0.3em;
  margin-left: -0.6em;
  width: .25em;
  -webkit-animation: km-webrefresh 0.6s infinite linear;
          animation: km-webrefresh 0.6s infinite linear;
}
.km-root .km-loading-left {
  margin-left: -0.2em;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.km-root .km-loading-right {
  margin-left: .42em;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
@keyframes km-webload {
  0% {
    height: 1em;
    margin-top: -0.5em;
  }
  33% {
    height: 2em;
    margin-top: -1em;
  }
  66% {
    height: 1em;
    margin-top: -0.5em;
  }
}
@-webkit-keyframes km-webload {
  0% {
    height: 1em;
    margin-top: -0.5em;
  }
  33% {
    height: 2em;
    margin-top: -1em;
  }
  66% {
    height: 1em;
    margin-top: -0.5em;
  }
}
@keyframes km-webrefresh {
  0% {
    height: .6em;
    margin-top: -0.3em;
  }
  33% {
    height: 1.2em;
    margin-top: -0.6em;
  }
  66% {
    height: .6em;
    margin-top: -0.3em;
  }
}
@-webkit-keyframes km-webrefresh {
  0% {
    height: .6em;
    margin-top: -0.3em;
  }
  33% {
    height: 1.2em;
    margin-top: -0.6em;
  }
  66% {
    height: .6em;
    margin-top: -0.3em;
  }
}
/* Icon per-widget styles */
.km-detail .km-icon,
.km-button .km-icon,
.km-list .km-icon,
.km-ios .km-button .km-icon {
  width: 1em;
  height: 1em;
  font-size: 1em;
  margin-left: -0.3em;
  margin-right: 0.3em;
  vertical-align: baseline;
  display: inline-block;
  -webkit-background-size: auto 100%;
          background-size: auto 100%;
}
html .km-widget .km-view .km-notext {
  margin-left: 0;
  margin-right: 0;
}
.km-buttongroup .km-button .km-icon {
  width: 1em;
  height: 1em;
  font-size: 1em;
  margin: .05em .16em 0 0;
}
.km-tabstrip .km-button .km-icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2.5rem;
}
.km-tabstrip .km-image,
.km-tabstrip .km-button .km-icon {
  margin: 0 auto .1em;
  display: inline-block;
}
.km-tabstrip .km-text {
  display: block;
}
.km-phone .km-tabstrip .km-icon {
  height: 2.2rem;
  width: 2.2rem;
  font-size: 2.2rem;
}
.km-phone .km-horizontal .km-tabstrip .km-icon {
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
}
/* Icons */
@font-face {
  font-family: "Kendo UI";
  src: url("images/kendoui.woff?v=1.1") format("woff"), url("images/kendoui.ttf?v=1.1") format("truetype"), url("images/kendoui.svg#kendoui") format("svg");
}
body:before {
  font-family: "Kendo UI";
  content: "\a0";
  font-size: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}
.km-root .km-pane .km-view .km-icon {
  -webkit-background-clip: text;
  -webkit-background-size: 0 0;
          background-size: 0 0;
}
.km-icon {
  position: relative;
}
.km-icon:after,
.km-icon:before,
.km-contactadd:after,
.km-contactadd:before,
.km-rowdelete:after,
.km-rowdelete:before,
.km-rowinsert:after,
.km-rowinsert:before,
.km-detaildisclose:after,
.km-detaildisclose:before,
.km-loading:after,
.km-filter-wrap:before {
  position: relative;
  content: "\a0";
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  vertical-align: middle;
  -webkit-background-size: auto auto;
          background-size: auto;
  font: 1em/1em "Kendo UI";
}
.km-icon:before,
.km-contactadd:before,
.km-rowdelete:before,
.km-rowinsert:before,
.km-detaildisclose:before {
  position: absolute;
  margin-top: 1px;
  color: rgba(0, 0, 0, 0.7);
  display: none;
}
.km-state-active .km-icon:before,
.km-state-active .km-contactadd:before,
.km-state-active .km-rowdelete:before,
.km-state-active .km-rowinsert:before,
.km-state-active .km-detaildisclose:before {
  display: block;
}
.km-ios7 .km-detaildisclose:after {
  font-family: serif;
}
.km-ios7 .km-icon:before,
.km-ios7 .km-contactadd:before,
.km-ios7 .km-rowdelete:before,
.km-ios7 .km-rowinsert:before,
.km-ios7 .km-detaildisclose:before {
  display: none;
}
.k-webkit .km-ios:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after,
.k-webkit .km-blackberry:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after,
.k-safari .km-ios:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after,
.k-safari .km-blackberry:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after {
  background-image: inherit;
  background-repeat: inherit;
  background-position: inherit;
  background-color: currentcolor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.km-root .km-pane.km-on-blackberry.km-blackberry6 .km-view .km-icon:after,
.km-root .km-pane.km-on-blackberry.km-blackberry7 .km-view .km-icon:after,
.km-root .km-pane.km-pane.km-on-android .km-view .km-icon:after,
.km-root .km-pane.km-pane.km-on-meego .km-view .km-icon:after {
  background: none;
  -webkit-text-fill-color: inherit;
}
.km-contactadd:after,
.km-contactadd:before,
.km-rowinsert:after,
.km-rowinsert:before {
  content: "\E039";
}
.km-rowdelete:after,
.km-rowdelete:before {
  content: "\E03a";
}
.km-detaildisclose:after,
.km-detaildisclose:before {
  content: "\E0E2";
}
.km-action:after,
.km-action:before {
  content: "\e0ca";
}
.km-add:after,
.km-add:before {
  content: "\e0cb";
}
.km-arrow-n:after,
.km-arrow-n:before {
  content: "\e001";
}
.km-arrow-s:after,
.km-arrow-s:before {
  content: "\e002";
}
.km-battery:after,
.km-battery:before {
  content: "\e0ce";
}
.km-bookmarks:after,
.km-bookmarks:before {
  content: "\e0cf";
}
.km-camera:after,
.km-camera:before {
  content: "\e0d0";
}
.km-cart:after,
.km-cart:before {
  content: "\e0d1";
}
.km-edit:after,
.km-compose:after,
.km-edit:before,
.km-compose:before {
  content: "\e0d5";
}
.km-contacts:after,
.km-contacts:before {
  content: "\e0e4";
}
.km-trash:after,
.km-delete:after,
.km-trash:before,
.km-delete:before {
  content: "\e0ec";
}
.km-details:after,
.km-details:before {
  content: "\e0e2";
}
.km-download:after,
.km-downloads:after,
.km-download:before,
.km-downloads:before {
  content: "\e0d4";
}
.km-fastforward:after,
.km-fastforward:before {
  content: "\e0d9";
}
.km-toprated:after,
.km-favorites:after,
.km-toprated:before,
.km-favorites:before {
  content: "\e0d7";
}
.km-featured:after,
.km-featured:before {
  content: "\e0d8";
}
.km-globe:after,
.km-globe:before {
  content: "\e0dc";
}
.km-history:after,
.km-history:before {
  content: "\e0e7";
}
.km-home:after,
.km-home:before {
  content: "\e0dd";
}
.km-info:after,
.km-about:after,
.km-info:before,
.km-about:before {
  content: "\e0de";
}
.km-minus:after,
.km-minus:before {
  content: "\e033";
}
.km-more:after,
.km-more:before {
  content: "\e0e0";
}
.km-mostrecent:after,
.km-mostrecent:before {
  content: "\e0cc";
}
.km-mostviewed:after,
.km-mostviewed:before {
  content: "\e0d6";
}
.km-organize:after,
.km-organize:before {
  content: "\e0eb";
}
.km-pause:after,
.km-pause:before {
  content: "\e0e3";
}
.km-play:after,
.km-play:before {
  content: "\e0e5";
}
.km-plus:after,
.km-plus:before {
  content: "\e032";
}
.km-recents:after,
.km-recents:before {
  content: "\e0d2";
}
.km-refresh:after,
.km-refresh:before {
  content: "\e0e6";
}
.km-reply:after,
.km-reply:before {
  content: "\e0ed";
}
.km-rewind:after,
.km-rewind:before {
  content: "\e0e8";
}
.km-search:after,
.km-search:before {
  content: "\e0e9";
}
.km-settings:after,
.km-settings:before {
  content: "\e0da";
}
.km-share:after,
.km-share:before {
  content: "\e0df";
}
.km-sounds:after,
.km-volume:after,
.km-sounds:before,
.km-volume:before {
  content: "\e0ef";
}
.km-stop:after,
.km-stop:before {
  content: "\e0ea";
}
.km-wifi:after,
.km-wifi:before {
  content: "\e0f0";
}
.km-drawer-icon:after,
.km-drawer-icon:before,
.km-hamburger:before,
.km-hamburger:after {
  content: "\e105";
}
.km-root .km-pane .km-icon.km-check {
  -webkit-background-clip: initial;
}
.km-root .km-pane .km-check:checked:after,
.km-widget .km-check:checked:after {
  content: "\e227";
}
.km-android .km-more:after,
.km-android .km-more:before {
  content: "\e0e1";
}
.km-meego .km-more:after,
.km-meego .km-more:before {
  content: "\e0f1";
}
.km-wp .km-loading:after,
.km-wp .km-load-more .km-icon:after,
.km-wp .km-scroller-refresh .km-icon:after {
  content: "\e0f6";
}
.km-meego .km-loading:after,
.km-meego .km-load-more .km-icon:after,
.km-meego .km-scroller-refresh .km-icon:after {
  content: "\e0f6";
}
.km-root .km-android .km-loading:after,
.km-android .km-load-more .km-icon:after,
.km-root .km-android .km-scroller-refresh .km-icon:after {
  content: "\e0f6";
}
.km-scroller-pull .km-icon:after {
  content: "\e0f2";
}
.km-icon.km-phone:after,
.km-ios7 .km-state-active .km-phone:after {
  content: "\e326";
}
.km-ios7 .km-detaildisclose:after {
  content: "i";
}
.km-ios7 .km-action:after {
  content: "\e1ff";
}
.km-ios7 .km-add:after {
  content: "\e200";
}
.km-ios7 .km-mostrecent:after {
  content: "\e201";
}
.km-ios7 .km-battery:after {
  content: "\e203";
}
.km-ios7 .km-bookmarks:after {
  content: "\e204";
}
.km-ios7 .km-camera:after {
  content: "\e205";
}
.km-ios7 .km-cart:after {
  content: "\e206";
}
.km-ios7 .km-recents:after {
  content: "\e207";
}
.km-ios7 .km-download:after,
.km-ios7 .km-downloads:after {
  content: "\e209";
}
.km-ios7 .km-edit:after {
  content: "\e20a";
}
.km-ios7 .km-mostviewed:after {
  content: "\e20b";
}
.km-ios7 .km-toprated:after,
.km-ios7 .km-favorites:after {
  content: "\e20c";
}
.km-ios7 .km-featured:after {
  content: "\e20d";
}
.km-ios7 .km-fastforward:after {
  content: "\e20e";
}
.km-ios7 .km-settings:after {
  content: "\e20f";
}
.km-ios7 .km-globe:after {
  content: "\e211";
}
.km-ios7 .km-home:after {
  content: "\e212";
}
.km-ios7 .km-info:after,
.km-ios7 .km-about:after {
  content: "\e213";
}
.km-ios7 .km-share:after {
  content: "\e214";
}
.km-ios7 .km-more:after {
  content: "\e215";
}
.km-ios7 .km-details:after {
  content: "\e217";
}
.km-ios7 .km-pause:after {
  content: "\e218";
}
.km-ios7 .km-contacts:after {
  content: "\e219";
}
.km-ios7 .km-play:after {
  content: "\e21a";
}
.km-ios7 .km-refresh:after {
  content: "\e21b";
}
.km-ios7 .km-history:after {
  content: "\e21c";
}
.km-ios7 .km-rewind:after {
  content: "\e21d";
}
.km-ios7 .km-search:after {
  content: "\e21e";
}
.km-ios7 .km-stop:after {
  content: "\e21f";
}
.km-ios7 .km-organize:after {
  content: "\e220";
}
.km-ios7 .km-trash:after,
.km-ios7 .km-delete:after {
  content: "\e221";
}
.km-ios7 .km-reply:after {
  content: "\e222";
}
.km-ios7 .km-forward:after {
  content: "\e223";
}
.km-ios7 .km-sounds:after,
.km-ios7 .km-volume:after {
  content: "\e224";
}
.km-ios7 .km-wifi:after {
  content: "\e225";
}
.km-ios7 .km-phone:after {
  content: "\e226";
}
.km-ios7 .km-state-active .km-action:after {
  content: "\e2ff";
}
.km-ios7 .km-state-active .km-add:after {
  content: "\e300";
}
.km-ios7 .km-state-active .km-mostrecent:after {
  content: "\e301";
}
.km-ios7 .km-state-active .km-battery:after {
  content: "\e303";
}
.km-ios7 .km-state-active .km-bookmarks:after {
  content: "\e304";
}
.km-ios7 .km-state-active .km-camera:after {
  content: "\e305";
}
.km-ios7 .km-state-active .km-cart:after {
  content: "\e306";
}
.km-ios7 .km-state-active .km-recents:after {
  content: "\e307";
}
.km-ios7 .km-state-active .km-download:after,
.km-ios7 .km-state-active .km-downloads:after {
  content: "\e309";
}
.km-ios7 .km-state-active .km-edit:after {
  content: "\e30a";
}
.km-ios7 .km-state-active .km-mostviewed:after {
  content: "\e30b";
}
.km-ios7 .km-state-active .km-toprated:after,
.km-ios7 .km-state-active .km-favorites:after {
  content: "\e30c";
}
.km-ios7 .km-state-active .km-featured:after {
  content: "\e30d";
}
.km-ios7 .km-state-active .km-fastforward:after {
  content: "\e30e";
}
.km-ios7 .km-state-active .km-settings:after {
  content: "\e30f";
}
.km-ios7 .km-state-active .km-globe:after {
  content: "\e311";
}
.km-ios7 .km-state-active .km-home:after {
  content: "\e312";
}
.km-ios7 .km-state-active .km-info:after,
.km-ios7 .km-state-active .km-about:after {
  content: "\e313";
}
.km-ios7 .km-state-active .km-share:after {
  content: "\e314";
}
.km-ios7 .km-state-active .km-more:after {
  content: "\e315";
}
.km-ios7 .km-state-active .km-details:after {
  content: "\e317";
}
.km-ios7 .km-state-active .km-pause:after {
  content: "\e318";
}
.km-ios7 .km-state-active .km-contacts:after {
  content: "\e319";
}
.km-ios7 .km-state-active .km-play:after {
  content: "\e31a";
}
.km-ios7 .km-state-active .km-refresh:after {
  content: "\e31b";
}
.km-ios7 .km-state-active .km-history:after {
  content: "\e31c";
}
.km-ios7 .km-state-active .km-rewind:after {
  content: "\e31d";
}
.km-ios7 .km-state-active .km-search:after {
  content: "\e31e";
}
.km-ios7 .km-state-active .km-stop:after {
  content: "\e31f";
}
.km-ios7 .km-state-active .km-organize:after {
  content: "\e320";
}
.km-ios7 .km-state-active .km-trash:after,
.km-ios7 .km-state-active .km-delete:after {
  content: "\e321";
}
.km-ios7 .km-state-active .km-reply:after {
  content: "\e322";
}
.km-ios7 .km-state-active .km-forward:after {
  content: "\e323";
}
.km-ios7 .km-state-active .km-sounds:after,
.km-ios7 .km-state-active .km-volume:after {
  content: "\e324";
}
.km-ios7 .km-state-active .km-wifi:after {
  content: "\e325";
}
.km-arrowdown:after,
.km-arrowdown:before {
  content: "\e002";
}
.km-wp .km-scroller-pull .km-icon:after {
  content: "\E0D4";
}
.km-on-wp.km-app .km-icon:after,
.km-on-wp.km-app .km-filter-wrap:before,
.km-on-wp.km-app .km-state-active .km-icon:after {
  color: transparent;
  background-image: url("images/wp8_icons.png");
  -webkit-background-size: auto 100%;
          background-size: auto 100%;
  height: 1em;
  margin-top: 0;
  vertical-align: middle;
}
.km-wp-light.km-app .km-icon:after,
.km-wp-light.km-app .km-filter-wrap:before {
  background-image: url("images/wp8_inverseicons.png");
}
.km-on-wp.km-app .km-icon {
  line-height: 1em;
}
.km-on-wp.km-app .km-icon:before {
  display: none;
}
.km-on-wp.km-app .km-action:after {
  background-position-x: 20%;
}
.km-on-wp.km-app .km-add:after,
.km-on-wp.km-app .km-filter-reset .km-clear:after {
  background-position-x: 22%;
}
.km-on-wp.km-app .km-battery:after {
  background-position-x: 24%;
}
.km-on-wp.km-app .km-bookmarks:after {
  background-position-x: 26%;
}
.km-on-wp.km-app .km-camera:after {
  background-position-x: 28%;
}
.km-on-wp.km-app .km-cart:after {
  background-position-x: 30%;
}
.km-on-wp.km-app .km-edit:after,
.km-on-wp.km-app .km-compose:after {
  background-position-x: 32%;
}
.km-on-wp.km-app .km-contacts:after {
  background-position-x: 34%;
}
.km-on-wp.km-app .km-trash:after,
.km-on-wp.km-app .km-delete:after {
  background-position-x: 36%;
}
.km-on-wp.km-app .km-details:after {
  background-position-x: 38%;
}
.km-on-wp.km-app .km-download:after,
.km-on-wp.km-app .km-downloads:after {
  background-position-x: 40%;
}
.km-on-wp.km-app .km-fastforward:after {
  background-position-x: 42%;
}
.km-on-wp.km-app .km-toprated:after,
.km-on-wp.km-app .km-favorites:after {
  background-position-x: 44%;
}
.km-on-wp.km-app .km-featured:after {
  background-position-x: 46%;
}
.km-on-wp.km-app .km-globe:after {
  background-position-x: 48%;
}
.km-on-wp.km-app .km-history:after {
  background-position-x: 50%;
}
.km-on-wp.km-app .km-home:after {
  background-position-x: 52%;
}
.km-on-wp.km-app .km-info:after,
.km-on-wp.km-app .km-about:after {
  background-position-x: 54%;
}
.km-on-wp.km-app .km-more:after {
  background-position-x: 56%;
}
.km-on-wp.km-app .km-mostrecent:after {
  background-position-x: 58%;
}
.km-on-wp.km-app .km-mostviewed:after {
  background-position-x: 60%;
}
.km-on-wp.km-app .km-organize:after {
  background-position-x: 62%;
}
.km-on-wp.km-app .km-pause:after {
  background-position-x: 64%;
}
.km-on-wp.km-app .km-play:after {
  background-position-x: 66%;
}
.km-on-wp.km-app .km-recents:after {
  background-position-x: 68%;
}
.km-on-wp.km-app .km-refresh:after {
  background-position-x: 70%;
}
.km-on-wp.km-app .km-reply:after {
  background-position-x: 72%;
}
.km-on-wp.km-app .km-rewind:after {
  background-position-x: 74%;
}
.km-on-wp.km-app .km-search:after,
.km-on-wp.km-app .km-filter-wrap:before {
  background-position-x: 76%;
}
.km-on-wp.km-app .km-settings:after {
  background-position-x: 78%;
}
.km-on-wp.km-app .km-share:after {
  background-position-x: 80%;
}
.km-on-wp.km-app .km-sounds:after,
.km-on-wp.km-app .km-volume:after {
  background-position-x: 82%;
}
.km-on-wp.km-app .km-stop:after {
  background-position-x: 84%;
}
.km-on-wp.km-app .km-wifi:after {
  background-position-x: 86%;
}
.km-on-wp.km-app.km-android .km-more:after {
  background-position-x: 88%;
}
.km-on-wp.km-app.km-meego .km-more:after {
  background-position-x: 90%;
}
.km-on-wp.km-app.km-meego .km-loading:after,
.km-on-wp.km-app.km-meego .km-load-more .km-icon:after,
.km-on-wp.km-app.km-meego .km-scroller-refresh .km-icon:after {
  background-position-x: 94%;
}
.km-on-wp.km-app .km-scroller-pull .km-icon:after {
  background-position-x: 100%;
}
.km-on-wp.km-app .km-filter-wrap:before {
  display: inline-block;
  content: "\a0";
}
.km-on-wp.km-app .km-filter-reset .km-clear:after {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.km-list .km-icon-label.k-i-arrow-e:before {
  content: "\E000";
}
.km-list .km-icon-label.k-i-arrow-n:before {
  content: "\E001";
}
.km-list .km-icon-label.k-i-arrow-s:before {
  content: "\E002";
}
.km-list .km-icon-label.k-i-arrow-w:before {
  content: "\E003";
}
.km-list .km-icon-label.k-i-arrow-ne:before {
  content: "\E004";
}
.km-list .km-icon-label.k-i-arrow-nw:before {
  content: "\E005";
}
.km-list .km-icon-label.k-i-arrow-se:before {
  content: "\E006";
}
.km-list .km-icon-label.k-i-arrow-sw:before {
  content: "\E007";
}
.km-list .km-icon-label.k-i-seek-e:before {
  content: "\E008";
}
.km-list .km-icon-label.k-i-seek-n:before {
  content: "\E009";
}
.km-list .km-icon-label.k-i-seek-s:before {
  content: "\E00A";
}
.km-list .km-icon-label.k-i-seek-w:before {
  content: "\E00B";
}
.km-list .km-icon-label.k-si-arrow-e:before {
  content: "\E00C";
}
.km-list .km-icon-label.k-si-arrow-n:before {
  content: "\E00D";
}
.km-list .km-icon-label.k-si-arrow-s:before {
  content: "\E00E";
}
.km-list .km-icon-label.k-si-arrow-w:before {
  content: "\E00F";
}
.km-list .km-icon-label.k-i-arrowhead-e:before {
  content: "\E010";
}
.km-list .km-icon-label.k-i-arrowhead-n:before {
  content: "\E011";
}
.km-list .km-icon-label.k-i-arrowhead-s:before {
  content: "\E012";
}
.km-list .km-icon-label.k-i-arrowhead-w:before {
  content: "\E013";
}
.km-list .km-icon-label.k-i-arrowhead-ew:before {
  content: "\E014";
}
.km-list .km-icon-label.k-i-arrowhead-ns:before {
  content: "\E015";
}
.km-list .km-icon-label.k-i-move:before {
  content: "\E016";
}
.km-list .km-icon-label.k-i-resize:before {
  content: "\E017";
}
.km-list .km-icon-label.k-i-resize-45:before {
  content: "\E018";
}
.km-list .km-icon-label.k-i-resize-135:before {
  content: "\E019";
}
.km-list .km-icon-label.k-i-resize-h:before {
  content: "\E01A";
}
.km-list .km-icon-label.k-i-resize-v:before {
  content: "\E01B";
}
.km-list .km-icon-label.k-i-refresh:before {
  content: "\E01C";
}
.km-list .km-icon-label.k-i-refresh-rev:before {
  content: "\E01D";
}
.km-list .km-icon-label.k-si-refresh:before {
  content: "\E01E";
}
.km-list .km-icon-label.k-si-refresh-rev:before {
  content: "\E01F";
}
.km-list .km-icon-label.k-i-turn-en:before {
  content: "\E020";
}
.km-list .km-icon-label.k-i-turn-es:before {
  content: "\E021";
}
.km-list .km-icon-label.k-i-turn-ne:before {
  content: "\E022";
}
.km-list .km-icon-label.k-i-turn-nw:before {
  content: "\E023";
}
.km-list .km-icon-label.k-i-turn-se:before {
  content: "\E024";
}
.km-list .km-icon-label.k-i-turn-sw:before {
  content: "\E025";
}
.km-list .km-icon-label.k-i-turn-wn:before {
  content: "\E026";
}
.km-list .km-icon-label.k-i-turn-ws:before {
  content: "\E027";
}
.km-list .km-icon-label.k-i-uturn-e:before {
  content: "\E028";
}
.km-list .km-icon-label.k-i-uturn-e-rev:before {
  content: "\E029";
}
.km-list .km-icon-label.k-i-uturn-n:before {
  content: "\E02A";
}
.km-list .km-icon-label.k-i-uturn-n-rev:before {
  content: "\E02B";
}
.km-list .km-icon-label.k-i-uturn-s:before {
  content: "\E02C";
}
.km-list .km-icon-label.k-i-uturn-s-rev:before {
  content: "\E02D";
}
.km-list .km-icon-label.k-i-uturn-w:before {
  content: "\E02E";
}
.km-list .km-icon-label.k-i-uturn-w-rev:before {
  content: "\E02F";
}
.km-list .km-icon-label.k-i-tick-sign:before {
  content: "\E030";
}
.km-list .km-icon-label.k-i-close-sign:before {
  content: "\E031";
}
.km-list .km-icon-label.k-i-plus-sign:before {
  content: "\E032";
}
.km-list .km-icon-label.k-i-minus-sign:before {
  content: "\E033";
}
.km-list .km-icon-label.k-si-plus-sign:before {
  content: "\E034";
}
.km-list .km-icon-label.k-si-minus-sign:before {
  content: "\E035";
}
.km-list .km-icon-label.k-i-cancel:before {
  content: "\E036";
}
.km-list .km-icon-label.k-i-tick:before {
  content: "\E037";
}
.km-list .km-icon-label.k-i-close:before {
  content: "\E038";
}
.km-list .km-icon-label.k-i-plus:before {
  content: "\E039";
}
.km-list .km-icon-label.k-i-minus:before {
  content: "\E03A";
}
.km-list .km-icon-label.k-si-plus:before {
  content: "\E03B";
}
.km-list .km-icon-label.k-si-minus:before {
  content: "\E03C";
}
.km-list .km-icon-label.k-i-stop:before {
  content: "\E03D";
}
.km-list .km-icon-label.k-i-pause:before {
  content: "\E03E";
}
.km-list .km-icon-label.k-i-eject:before {
  content: "\E03F";
}
.km-list .km-icon-label.k-i-volume-off:before {
  content: "\E040";
}
.km-list .km-icon-label.k-i-volume-low:before {
  content: "\E041";
}
.km-list .km-icon-label.k-i-volume-high:before {
  content: "\E042";
}
.km-list .km-icon-label.k-i-earphones:before {
  content: "\E043";
}
.km-list .km-icon-label.k-i-heart:before {
  content: "\E044";
}
.km-list .km-icon-label.k-i-heart-empty:before {
  content: "\E045";
}
.km-list .km-icon-label.k-i-heart-half:before {
  content: "\E046";
}
.km-list .km-icon-label.k-i-star:before {
  content: "\E047";
}
.km-list .km-icon-label.k-i-star-empty:before {
  content: "\E048";
}
.km-list .km-icon-label.k-i-star-half:before {
  content: "\E049";
}
.km-list .km-icon-label.k-i-star-half-empty:before {
  content: "\E04A";
}
.km-list .km-icon-label.k-i-chart-column:before {
  content: "\E04B";
}
.km-list .km-icon-label.k-i-chart-bar:before {
  content: "\E04C";
}
.km-list .km-icon-label.k-i-chart-pie:before {
  content: "\E04D";
}
.km-list .km-icon-label.k-i-chart-donut:before {
  content: "\E04E";
}
.km-list .km-icon-label.k-i-chart-line:before {
  content: "\E04F";
}
.km-list .km-icon-label.k-i-chart-area:before {
  content: "\E050";
}
.km-list .km-icon-label.k-i-chart-donut:before {
  content: "\E051";
}
.km-list .km-icon-label.k-i-align-left:before {
  content: "\E052";
}
.km-list .km-icon-label.k-i-align-center:before {
  content: "\E053";
}
.km-list .km-icon-label.k-i-align-right:before {
  content: "\E054";
}
.km-list .km-icon-label.k-i-align-justify:before {
  content: "\E055";
}
.km-list .km-icon-label.k-i-align-clear:before {
  content: "\E056";
}
.km-list .km-icon-label.k-i-bold:before {
  content: "\E057";
}
.km-list .km-icon-label.k-i-bold-sans:before {
  content: "\E058";
}
.km-list .km-icon-label.k-i-italic:before {
  content: "\E059";
}
.km-list .km-icon-label.k-i-italic-sans:before {
  content: "\E05A";
}
.km-list .km-icon-label.k-i-underline:before {
  content: "\E05B";
}
.km-list .km-icon-label.k-i-underline-sans:before {
  content: "\E05C";
}
.km-list .km-icon-label.k-i-strikethrough:before {
  content: "\E05D";
}
.km-list .km-icon-label.k-i-strikethrough-sans:before {
  content: "\E05E";
}
.km-list .km-icon-label.k-i-font-a:before {
  content: "\E05F";
}
.km-list .km-icon-label.k-i-font-a-sans:before {
  content: "\E060";
}
.km-list .km-icon-label.k-i-font-t:before {
  content: "\E061";
}
.km-list .km-icon-label.k-i-font-t-sans:before {
  content: "\E062";
}
.km-list .km-icon-label.k-i-brightness:before {
  content: "\E063";
}
.km-list .km-icon-label.k-i-brightness-contrast:before {
  content: "\E064";
}
.km-list .km-icon-label.k-i-contrast:before {
  content: "\E065";
}
.km-list .km-icon-label.k-i-crop:before {
  content: "\E066";
}
.km-list .km-icon-label.k-i-mirror:before {
  content: "\E067";
}
.km-list .km-icon-label.k-i-flip-h:before {
  content: "\E068";
}
.km-list .km-icon-label.k-i-flip-v:before {
  content: "\E069";
}
.km-list .km-icon-label.k-i-rotate:before {
  content: "\E06A";
}
.km-list .km-icon-label.k-i-rotate-rev:before {
  content: "\E06B";
}
.km-list .km-icon-label.k-i-filter:before {
  content: "\E06C";
}
.km-list .km-icon-label.k-i-filter-clear:before {
  content: "\E06D";
}
.km-list .km-icon-label.k-i-sort-asc:before {
  content: "\E06E";
}
.km-list .km-icon-label.k-i-sort-desc:before {
  content: "\E06F";
}
.km-list .km-icon-label.k-i-sort-clear:before {
  content: "\E070";
}
.km-list .km-icon-label.k-i-indent:before {
  content: "\E071";
}
.km-list .km-icon-label.k-i-outdent:before {
  content: "\E072";
}
.km-list .km-icon-label.k-i-hyperlink:before {
  content: "\E073";
}
.km-list .km-icon-label.k-i-hyperlink-clear:before {
  content: "\E074";
}
.km-list .km-icon-label.k-i-hyperlink-ext:before {
  content: "\E075";
}
.km-list .km-icon-label.k-si-hyperlink-ext:before {
  content: "\E076";
}
.km-list .km-icon-label.k-i-ul:before {
  content: "\E077";
}
.km-list .km-icon-label.k-si-ul:before {
  content: "\E078";
}
.km-list .km-icon-label.k-i-paint:before {
  content: "\E079";
}
.km-list .km-icon-label.k-i-paste:before {
  content: "\E07A";
}
.km-list .km-icon-label.k-i-pencil:before {
  content: "\E07B";
}
.km-list .km-icon-label.k-i-image:before {
  content: "\E07C";
}
.km-list .km-icon-label.k-i-image-add:before {
  content: "\E07D";
}
.km-list .km-icon-label.k-i-print:before {
  content: "\E07E";
}
.km-list .km-icon-label.k-i-zoom:before {
  content: "\E07F";
}
.km-list .km-icon-label.k-i-zoom-in:before {
  content: "\E080";
}
.km-list .km-icon-label.k-i-zoom-out:before {
  content: "\E081";
}
.km-list .km-icon-label.k-i-asterisk:before {
  content: "\E082";
}
.km-list .km-icon-label.k-i-clip:before {
  content: "\E083";
}
.km-list .km-icon-label.k-i-clip-45:before {
  content: "\E084";
}
.km-list .km-icon-label.k-i-qrcode:before {
  content: "\E085";
}
.km-list .km-icon-label.k-i-book:before {
  content: "\E086";
}
.km-list .km-icon-label.k-i-bookmark:before {
  content: "\E087";
}
.km-list .km-icon-label.k-i-briefcase:before {
  content: "\E088";
}
.km-list .km-icon-label.k-i-calendar:before {
  content: "\E089";
}
.km-list .km-icon-label.k-i-camera-still:before {
  content: "\E08A";
}
.km-list .km-icon-label.k-i-camera-video:before {
  content: "\E08B";
}
.km-list .km-icon-label.k-i-certificate:before {
  content: "\E08C";
}
.km-list .km-icon-label.k-i-clock:before {
  content: "\E08D";
}
.km-list .km-icon-label.k-i-cloud:before {
  content: "\E08E";
}
.km-list .km-icon-label.k-i-collapse:before {
  content: "\E08F";
}
.km-list .km-icon-label.k-i-columns:before {
  content: "\E090";
}
.km-list .km-icon-label.k-i-comment:before {
  content: "\E091";
}
.km-list .km-icon-label.k-i-comment-empty:before {
  content: "\E092";
}
.km-list .km-icon-label.k-i-comments:before {
  content: "\E093";
}
.km-list .km-icon-label.k-i-comments-empty:before {
  content: "\E094";
}
.km-list .km-icon-label.k-i-credit-card:before {
  content: "\E095";
}
.km-list .km-icon-label.k-i-download:before {
  content: "\E096";
}
.km-list .km-icon-label.k-i-draghandle:before {
  content: "\E097";
}
.km-list .km-icon-label.k-si-draghandle:before {
  content: "\E098";
}
.km-list .km-icon-label.k-i-envelop:before {
  content: "\E099";
}
.km-list .km-icon-label.k-i-envelop-open:before {
  content: "\E09A";
}
.km-list .km-icon-label.k-i-eye:before {
  content: "\E09B";
}
.km-list .km-icon-label.k-i-file:before {
  content: "\E09C";
}
.km-list .km-icon-label.k-i-file-add:before {
  content: "\E09D";
}
.km-list .km-icon-label.k-i-film:before {
  content: "\E09E";
}
.km-list .km-icon-label.k-i-flag:before {
  content: "\E09F";
}
.km-list .km-icon-label.k-i-folder-add:before {
  content: "\E0A0";
}
.km-list .km-icon-label.k-i-folder:before {
  content: "\E0A1";
}
.km-list .km-icon-label.k-i-folder-open:before {
  content: "\E0A2";
}
.km-list .km-icon-label.k-i-folder-up:before {
  content: "\E0A3";
}
.km-list .km-icon-label.k-i-gear:before {
  content: "\E0A4";
}
.km-list .km-icon-label.k-si-gear:before {
  content: "\E0A5";
}
.km-list .km-icon-label.k-i-transmit:before {
  content: "\E0A6";
}
.km-list .km-icon-label.k-i-beer:before {
  content: "\E0A7";
}
.km-list .km-icon-label.k-i-cocktail:before {
  content: "\E0A8";
}
.km-list .km-icon-label.k-i-coffee:before {
  content: "\E0A9";
}
.km-list .km-icon-label.k-i-wine:before {
  content: "\E0AA";
}
.km-list .km-icon-label.k-i-grid:before {
  content: "\E0AB";
}
.km-list .km-icon-label.k-i-thumbs:before {
  content: "\E0AC";
}
.km-list .km-icon-label.k-i-split-h:before {
  content: "\E0AD";
}
.km-list .km-icon-label.k-i-split-v:before {
  content: "\E0AE";
}
.km-list .km-icon-label.k-i-home:before {
  content: "\E0AF";
}
.km-list .km-icon-label.k-i-inbox:before {
  content: "\E0B0";
}
.km-list .km-icon-label.k-i-key:before {
  content: "\E0B1";
}
.km-list .km-icon-label.k-i-login:before {
  content: "\E0B2";
}
.km-list .km-icon-label.k-i-logout:before {
  content: "\E0B3";
}
.km-list .km-icon-label.k-i-place:before {
  content: "\E0B4";
}
.km-list .km-icon-label.k-i-megaphone:before {
  content: "\E0B5";
}
.km-list .km-icon-label.k-i-note:before {
  content: "\E0B6";
}
.km-list .km-icon-label.k-i-pin:before {
  content: "\E0B7";
}
.km-list .km-icon-label.k-i-unpin:before {
  content: "\E0B8";
}
.km-list .km-icon-label.k-i-power:before {
  content: "\E0B9";
}
.km-list .km-icon-label.k-i-progress-bars:before {
  content: "\E0BA";
}
.km-list .km-icon-label.k-i-road:before {
  content: "\E0BB";
}
.km-list .km-icon-label.k-i-rss:before {
  content: "\E0BC";
}
.km-list .km-icon-label.k-i-floppy:before {
  content: "\E0BD";
}
.km-list .km-icon-label.k-i-sitemap:before {
  content: "\E0BE";
}
.km-list .km-icon-label.k-i-tag-45:before {
  content: "\E0BF";
}
.km-list .km-icon-label.k-i-tag-h:before {
  content: "\E0C0";
}
.km-list .km-icon-label.k-i-thunderbolt:before {
  content: "\E0C1";
}
.km-list .km-icon-label.k-i-tooltip:before {
  content: "\E0C2";
}
.km-list .km-icon-label.k-i-trash:before {
  content: "\E0C3";
}
.km-list .km-icon-label.k-i-trophy:before {
  content: "\E0C4";
}
.km-list .km-icon-label.k-i-ungroup:before {
  content: "\E0C5";
}
.km-list .km-icon-label.k-i-upload:before {
  content: "\E0C6";
}
.km-list .km-icon-label.k-i-window:before {
  content: "\E0C7";
}
.km-list .km-icon-label.k-i-tiles:before {
  content: "\E0C8";
}
.km-list .km-icon-label.k-i-wrench:before {
  content: "\E0C9";
}
.km-list .km-icon-label.k-i-action:before {
  content: "\E0CA";
}
.km-list .km-icon-label.k-i-add:before {
  content: "\E0CB";
}
.km-list .km-icon-label.k-i-add-inv:before {
  content: "\E0CC";
}
.km-list .km-icon-label.k-i-armchair:before {
  content: "\E0CD";
}
.km-list .km-icon-label.k-i-battery:before {
  content: "\E0CE";
}
.km-list .km-icon-label.k-i-book-open:before {
  content: "\E0CF";
}
.km-list .km-icon-label.k-i-camera:before {
  content: "\E0D0";
}
.km-list .km-icon-label.k-i-cart:before {
  content: "\E0D1";
}
.km-list .km-icon-label.k-i-time:before {
  content: "\E0D2";
}
.km-list .km-icon-label.k-i-coffee-card:before {
  content: "\E0D3";
}
.km-list .km-icon-label.k-i-download-arrow:before {
  content: "\E0D4";
}
.km-list .km-icon-label.k-i-edit:before {
  content: "\E0D5";
}
.km-list .km-icon-label.k-i-faves:before {
  content: "\E0D6";
}
.km-list .km-icon-label.k-i-star:before {
  content: "\E0D7";
}
.km-list .km-icon-label.k-i-featured:before {
  content: "\E0D8";
}
.km-list .km-icon-label.k-i-forward:before {
  content: "\E0D9";
}
.km-list .km-icon-label.k-i-cog:before {
  content: "\E0DA";
}
.km-list .km-icon-label.k-i-globe:before {
  content: "\E0DB";
}
.km-list .km-icon-label.k-i-globe-inv:before {
  content: "\E0DC";
}
.km-list .km-icon-label.k-i-house:before {
  content: "\E0DD";
}
.km-list .km-icon-label.k-i-info:before {
  content: "\E0DE";
}
.km-list .km-icon-label.k-i-share:before {
  content: "\E0DF";
}
.km-list .km-icon-label.k-i-more-h:before {
  content: "\E0E0";
}
.km-list .km-icon-label.k-i-more-v:before {
  content: "\E0E1";
}
.km-list .km-icon-label.k-i-next:before {
  content: "\E0E2";
}
.km-list .km-icon-label.k-i-pause-a:before {
  content: "\E0E3";
}
.km-list .km-icon-label.k-i-user:before {
  content: "\E0E4";
}
.km-list .km-icon-label.k-i-play-a:before {
  content: "\E0E5";
}
.km-list .km-icon-label.k-i-refresh-a:before {
  content: "\E0E6";
}
.km-list .km-icon-label.k-i-reset:before {
  content: "\E0E7";
}
.km-list .km-icon-label.k-i-rewind:before {
  content: "\E0E8";
}
.km-list .km-icon-label.k-i-search-a:before {
  content: "\E0E9";
}
.km-list .km-icon-label.k-i-stop-a:before {
  content: "\E0EA";
}
.km-list .km-icon-label.k-i-tiles-a:before {
  content: "\E0EB";
}
.km-list .km-icon-label.k-i-trash-a:before {
  content: "\E0EC";
}
.km-list .km-icon-label.k-i-undo:before {
  content: "\E0ED";
}
.km-list .km-icon-label.k-i-redo:before {
  content: "\E0EE";
}
.km-list .km-icon-label.k-i-volume-a:before {
  content: "\E0EF";
}
.km-list .km-icon-label.k-i-wifi:before {
  content: "\E0F0";
}
.km-list .km-icon-label.k-i-more-lines:before {
  content: "\E0F1";
}
.km-list .km-icon-label.k-i-pull-to-ref:before {
  content: "\E0F2";
}
.km-list .km-icon-label.k-i-loading-android:before {
  content: "\E0F3";
}
.km-list .km-icon-label.k-i-loading-blackberry:before {
  content: "\E0F4";
}
.km-list .km-icon-label.k-i-loading-meego:before {
  content: "\E0F5";
}
.km-list .km-icon-label.k-i-loading-custom:before {
  content: "\E0F6";
}
.km-list .km-icon-label.k-i-loading-ios:before {
  content: "\E0F7";
}
.km-list .km-icon-label.k-i-bug:before {
  content: "\E0F8";
}
.km-list .km-icon-label.k-i-info:before {
  content: "\E0F9";
}
.km-list .km-icon-label.k-i-warning:before {
  content: "\E0FA";
}
.km-list .km-icon-label.k-i-question:before {
  content: "\E0FB";
}
.km-list .km-icon-label.k-i-insert-n:before {
  content: "\E0FC";
}
.km-list .km-icon-label.k-i-insert-m:before {
  content: "\E0FD";
}
.km-list .km-icon-label.k-i-insert-s:before {
  content: "\E0FE";
}
.km-list .km-icon-label.k-i-lock:before {
  content: "\E0FF";
}
.km-list .km-icon-label.k-i-unlock:before {
  content: "\E100";
}
.km-list .km-icon-label.k-i-phone:before {
  content: "\E101";
}
.km-list .km-icon-label.k-i-tablet:before {
  content: "\E102";
}
.km-list .km-icon-label.k-i-ol:before {
  content: "\E103";
}
.km-list .km-icon-label.k-i-barcode:before {
  content: "\E104";
}
.km-list .km-icon-label.k-i-html5:before {
  content: "\E105";
}
.km-list .km-icon-label.k-i-css3:before {
  content: "\E106";
}
.km-list .km-icon-label.k-i-kendoui:before {
  content: "\E107";
}
.km-list .km-icon-label.k-i-telerik:before {
  content: "\E108";
}
.km-list .km-icon-label.k-i-icenium:before {
  content: "\E109";
}
.km-list .km-icon-label.k-i-sitefinity:before {
  content: "\E10A";
}
.km-list .km-icon-label.k-i-twitter:before {
  content: "\E10B";
}
.km-list .km-icon-label.k-i-linkedin:before {
  content: "\E10C";
}
.km-list .km-icon-label.k-i-facebook:before {
  content: "\E10D";
}
.km-list .km-icon-label.k-i-pinterest:before {
  content: "\E10E";
}
.km-list .km-icon-label.k-i-youtube:before {
  content: "\E10F";
}
.km-list .km-icon-label.k-i-vimeo:before {
  content: "\E110";
}
.km-list .km-icon-label.k-i-behance:before {
  content: "\E111";
}
.km-list .km-icon-label.k-i-dribbble:before {
  content: "\E112";
}
.km-list .km-icon-label.k-i-googleplus:before {
  content: "\E113";
}
.km-list .km-icon-label.k-i-minimize:before {
  content: "\E114";
}
.km-list .km-icon-label.k-i-html:before {
  content: "\E115";
}
.km-list .km-icon-label.k-i-group:before {
  content: "\E116";
}
.km-list .km-icon-label.k-i-subscript:before {
  content: "\E117";
}
.km-list .km-icon-label.k-i-superscript:before {
  content: "\E118";
}
.km-list .km-icon-label.k-i-drophere:before {
  content: "\E119";
}
/* Button */
.km-button {
  margin: 0;
  padding: .3em .8em;
  border-width: 1px;
  border-style: solid;
}
.km-button:hover {
  text-decoration: none;
}
/* Detail Button */
.km-badge {
  border: 0;
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 5em;
}
.km-detail {
  border-radius: 5em;
  min-width: 1.4em;
  height: 1.4em;
  line-height: 1.4em;
  border-width: 1px;
  border-style: solid;
}
.km-detaildisclose {
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
}
.km-detaildisclose:after,
.km-detaildisclose:before {
  left: .1em;
  top: 0.35em;
}
.km-detail .km-icon:before {
  display: none;
}
/* ButtonGroup */
.km-buttongroup {
  vertical-align: middle;
  background: transparent;
  padding: 0;
  margin: 0;
}
.km-buttongroup > * {
  padding: .36em .8em;
}
.km-buttongroup > *:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.km-widget.km-buttongroup > *:last-child,
.km-widget.km-buttongroup > *.km-state-active {
  border-right-width: 1px;
}
.km-widget.km-buttongroup > *.km-state-active + * {
  border-left-width: 0;
}
/* NavBar */
.km-navbar {
  border-width: 1px;
  border-style: solid;
}
.km-header .km-navbar {
  border-width: 0 0 1px;
}
.km-footer .km-navbar {
  border-width: 1px 0 0;
}
/* TabStrip */
.km-tabstrip {
  padding: 0;
  display: table;
  table-layout: fixed;
}
.km-tabstrip .km-button {
  font-size: .7em;
  display: table-cell;
  border: 0;
}
.km-tabstrip .km-icon:before {
  display: none;
}
/* Switch */
.km-switch {
  width: 4.2em;
  height: 1.9em;
  line-height: 1.76em;
  overflow: hidden;
  vertical-align: middle;
}
.km-switch-wrapper {
  overflow: hidden;
}
.km-switch-background {
  width: 118%;
  position: relative;
  left: -95%;
  margin-left: 0;
  background: currentcolor;
}
.km-switch .km-switch-background {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.km-switch-container {
  padding: 1px 0 1px 1px;
  border-width: 0;
}
.km-switch-handle {
  width: 1.6em;
  margin: 0 4px 0 0;
  border: 1px solid transparent;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  -webkit-box-shadow: 0 1px 0 1px currentcolor, 0 -1px 0 1px currentcolor;
  box-shadow: 0 1px 0 1px currentcolor, 0 -1px 0 1px currentcolor;
}
.km-switch-label-off {
  left: 101%;
}
.km-switch-label-on {
  left: -171%;
}
.km-switch-label-on,
.km-switch-label-off {
  text-shadow: none;
  width: 170%;
  font-size: 1em;
  line-height: 1.76em;
  vertical-align: middle;
  top: -2px;
}
.km-switch-container,
.km-switch-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  -webkit-background-clip: content-box;
          background-clip: content-box;
}
/* ListView */
.km-list > li {
  border-style: solid;
  border-width: 0 0 1px 0;
}
.km-listinset > li:first-child,
.km-listgroupinset .km-list > li:first-child {
  border-style: solid;
  border-width: 1px;
}
.km-listinset > li,
.km-listgroupinset .km-list > li {
  border-width: 0 1px 1px 1px;
}
.km-listinset > li:last-child,
.km-listgroupinset .km-list > li:last-child {
  border-style: solid;
  border-width: 0 1px 1px 1px;
}
.km-listinset > li:first-child:last-child,
.km-listgroupinset .km-list > li:first-child:last-child {
  border-width: 1px;
}
.km-listview-link:after {
  border-color: currentcolor;
  border-width: .2rem .2rem 0 0;
}
.km-pane:not(.km-on-android) .km-listview-link:after {
  width: .66rem;
  height: .64rem;
  border-width: 0;
  -webkit-box-shadow: inset -0.2rem 0.2rem 0;
          box-shadow: inset -0.2rem 0.2rem 0;
}
.km-listinset li:first-child > .km-listview-link,
.km-listgroupinset li:first-child > .km-listview-link,
.km-listinset li:first-child > .km-listview-label,
.km-listgroupinset li:first-child > .km-listview-label {
  border-radius: 1px 1px 0 0;
}
.km-listinset li:last-child > .km-listview-link,
.km-listgroupinset li:last-child > .km-listview-link,
.km-listinset li:last-child > .km-listview-label,
.km-listgroupinset li:last-child > .km-listview-label {
  border-radius: 0 0 1px 1px;
}
.km-listinset li:first-child:last-child > .km-listview-link,
.km-listgroupinset li:first-child:last-child > .km-listview-link,
.km-listinset li:first-child:last-child > .km-listview-label,
.km-listgroupinset li:first-child:last-child > .km-listview-label {
  border-radius: 1px;
}
.km-group-title {
  border-style: solid;
  border-width: 1px 0;
}
.km-scroll-header .km-group-title {
  border-width: 0 0 1px;
}
.km-listgroupinset .km-group-title {
  border: 0;
  background: none;
}
.km-listview .km-switch {
  margin-top: -0.9rem;
}
/* Filter box */
.km-listview-wrapper form .km-filter-wrap > input {
  font-size: 1.2em;
  padding: .3em 1.8em;
}
.km-widget .km-filter-wrap:before {
  margin: 0.05em -1.2em 0 0.2em;
}
.km-filter-reset {
  margin: 0.05em 0 0 -2em;
}
.km-filter-reset .km-clear:after {
  content: "\e038";
}
.km-filter-wrap > input {
  border-width: 1px;
  border-style: solid;
}
.km-load-more {
  height: 3.4em;
}
.km-load-more .km-button {
  margin: 0 .8em;
  display: block;
}
.km-scroller-refresh.km-load-more {
  padding: 0;
  position: relative;
  margin: auto;
}
.km-scroller-refresh.km-load-more,
.km-scroller-pull {
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  top: .25em;
  white-space: nowrap;
}
.km-scroller-pull {
  left: 50%;
  margin: 0 0 0 -90px;
}
.km-scroller-pull .km-template {
  position: absolute;
  line-height: 2em;
  font-size: 1.2em;
  min-width: 0;
  top: 0;
  left: 3em;
}
.km-scroller-pull .km-icon {
  margin-right: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
}
.km-scroller-pull .km-icon:after {
  content: "\e012";
  margin-left: -3px;
}
.km-scroller-refresh .km-icon:after {
  display: none;
}
.km-scroller-refresh .km-loading-left {
  margin-left: -0.18em;
}
.km-scroller-refresh .km-loading-right {
  margin-left: .28em;
}
/* ScrollView */
.km-pages {
  padding-top: .5em;
}
.km-pages li {
  border-radius: 1em;
}
/* Forms */
.km-list input[type=password],
.km-list input[type=search],
.km-list input[type=number],
.km-list input[type=tel],
.km-list input[type=url],
.km-list input[type=email],
.km-list input[type=month],
.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list input[type=text]:not(.k-input),
.km-list select:not([multiple]),
.km-list .k-dropdown-wrap,
.km-list textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 1.1rem;
  min-width: 6em;
  border: 0;
  padding: .4em;
  outline: none;
  background: transparent;
}
.km-list .k-dropdown-wrap {
  padding: .2em;
}
.km-list .k-dropdown {
  margin-top: -1.05em;
  font-weight: normal;
}
.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=month],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list .k-dropdown {
  text-align: left;
}
.km-list .k-dropdown .k-dropdown-wrap {
  display: block;
  border-radius: 0;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
/* Checkboxes and Radios */
.km-listview-label input[type=checkbox] {
  margin-top: -0.7em;
}
.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox] {
  border-width: 1px;
  border-style: solid;
  width: 1.4em;
  height: 1.4em;
}
.km-listview-label input[type=radio] {
  width: 1.2em;
  height: 1.2em;
  border-radius: 1em;
}
.km-listview-label input[type=checkbox]:after {
  content: "\a0";
  display: block;
  width: 90%;
  height: 76%;
  -webkit-transform: scale(0.9, 1);
      -ms-transform: scale(0.9, 1);
          transform: scale(0.9, 1);
  -webkit-transform-origin: 10% 50%;
      -ms-transform-origin: 10% 50%;
          transform-origin: 10% 50%;
}
.km-listview-label input[type=checkbox]:checked:after {
  font-size: 1.4em;
}
.km-listview-label input[type=radio]:after {
  color: transparent;
}
/* PopUp + ActionSheet */
.km-actionsheet > li > a {
  font-size: 1.4em;
  font-weight: normal;
  text-align: center;
}
.km-actionsheet {
  max-height: 80%;
}
.km-actionsheet > li > a {
  display: block;
}
.km-actionsheet > li:last-child > a {
  border: 0;
}
.km-shim li.km-actionsheet-title,
.km-popup li.km-actionsheet-title {
  display: none;
}
/* PopOver */
.km-popup-wrapper {
  padding: 15px;
}
.km-popover-root .km-popup {
  border: 5px solid transparent;
}
.km-popover-root .km-popup .km-view,
.km-popover-root .km-popup .km-navbar,
.km-popover-root .km-popup .km-toolbar,
.km-popover-root .km-popup .km-tabstrip {
  background: none;
}
.km-popover-root .km-popup .km-header {
  padding: 0 5px;
  margin: -5px -5px 2px;
  -webkit-margin-collapse: separate;
}
.km-popup-arrow:after,
.km-popup-arrow:before {
  border-style: solid;
  border-width: 0 15px 15px;
}
.km-down .km-popup-arrow:before {
  margin-top: -1px;
}
.km-up .km-popup-arrow:after,
.km-up .km-popup-arrow:before {
  border-width: 15px 15px 0 15px;
}
.km-left .km-popup-arrow:after,
.km-left .km-popup-arrow:before {
  border-width: 15px 0 15px 15px;
}
.km-right .km-popup-arrow:after,
.km-right .km-popup-arrow:before {
  border-width: 15px 15px 15px 0;
}
/* Border-radius */
.km-buttongroup > *:first-child {
  border-radius: 2px 0 0 2px;
}
.km-buttongroup > *:last-child {
  border-radius: 0 2px 2px 0;
}
.km-listinset > li:first-child,
.km-popover-root .km-popup .km-header,
.km-listgroupinset .km-list > li:first-child {
  border-radius: 2px 2px 0 0;
}
.km-listinset > li:last-child,
.km-listgroupinset .km-list > li:last-child {
  border-radius: 0 0 2px 2px;
}
.km-button,
.km-switch-handle,
.km-filter-wrap > input,
.km-popover-root .km-popup,
.km-actionsheet-wrapper.km-popup,
.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox],
.km-listinset > li:first-child:last-child,
.km-listgroupinset .km-list > li:first-child:last-child {
  border-radius: 2px;
}
.km-switch-wrapper,
.km-switch-container,
.km-switch-background {
  border-radius: 3px;
}
.km-actionsheet-wrapper.km-popup {
  padding: 2px 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.km-widget,
.km-content,
.km-popover-root .km-content .km-widget {
  color: #444444;
  background: #fff;
}
.km-navbar {
  color: #ffffff;
  background: #3f51b5;
}
.km-view {
  background: transparent;
}
/* NavBar */
.km-navbar {
  border-color: rgba(68, 68, 68, 0.2);
}
/* Button */
.km-button {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(68, 68, 68, 0.2);
}
.km-state-active[style*=background] {
  -webkit-box-shadow: inset 0 0 0 1000px rgba(128, 128, 128, 0.4);
          box-shadow: inset 0 0 0 1000px rgba(128, 128, 128, 0.4);
}
/* Badges and Details */
.km-detail {
  color: #444444;
  border-color: rgba(68, 68, 68, 0.2);
  background-color: #fff;
}
.km-detail:active,
.km-state-active .km-detail {
  -webkit-box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
          box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
}
/* Switch */
.km-switch-wrapper {
  background-color: #bababa;
}
.km-switch-background {
  background-image: none;
  background-image: none, -webkit-gradient(linear, left top, left bottom, from(#bababa), to(#bababa));
  background-image: none, -webkit-linear-gradient(top, #bababa, #bababa);
  background-image: none, linear-gradient(to bottom, #bababa, #bababa);
  background-position: 4.3em 0;
  background-repeat: no-repeat;
  background-color: currentcolor;
}
.km-switch-handle {
  background: #fff;
}
.km-switch-off .km-switch-handle {
  -webkit-box-shadow: 0 1px 0 1px #bababa, 0 -1px 0 1px #bababa;
  box-shadow: 0 1px 0 1px #bababa, 0 -1px 0 1px #bababa;
}
.km-switch-wrapper,
.km-switch-container,
.km-switch-background {
  border-radius: 3px;
}
.km-switch-handle {
  border-radius: 2px;
}
.km-switch-container,
.km-switch-wrapper {
  border-color: #e6e6e6;
}
/* ListView */
.km-list > li {
  background: #f2f2f2;
  border-color: rgba(68, 68, 68, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
}
.km-listinset > li:first-child,
.km-listgroupinset .km-list > li:first-child {
  border-color: rgba(68, 68, 68, 0.2);
}
.km-listinset > li:last-child,
.km-listgroupinset .km-list > li:last-child {
  border-color: rgba(68, 68, 68, 0.2);
}
.km-listview-link:after {
  color: #919191;
}
.km-group-title {
  background: #fff;
  border-color: rgba(68, 68, 68, 0.2);
}
/* ScrollView */
.km-pages li {
  background: rgba(68, 68, 68, 0.2);
}
/* Filter box */
.km-filter-wrap:before,
.km-filter-reset .km-clear {
  color: #444444;
}
.km-filter-wrap > input {
  color: #444444;
  border-color: rgba(68, 68, 68, 0.2);
}
.km-filter-wrap > input:focus {
  border-color: #000;
}
/* Forms */
.km-list input[type=password],
.km-list input[type=search],
.km-list input[type=number],
.km-list input[type=tel],
.km-list input[type=url],
.km-list input[type=email],
.km-list input[type=month],
.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list input[type=text]:not(.k-input),
.km-list select:not([multiple]),
.km-list .k-dropdown-wrap,
.km-list textarea {
  color: #444444;
}
.km-list .k-dropdown-wrap .k-input {
  color: #444444;
}
.km-list select:not([multiple]) option {
  color: #333;
}
.km-list .km-state-active input[type=password],
.km-list .km-state-active input[type=search],
.km-list .km-state-active input[type=number],
.km-list .km-state-active input[type=tel],
.km-list .km-state-active input[type=url],
.km-list .km-state-active input[type=email],
.km-list .km-state-active input[type=month],
.km-list .km-state-active input[type=color],
.km-list .km-state-active input[type=week],
.km-list .km-state-active input[type=date],
.km-list .km-state-active input[type=time],
.km-list .km-state-active input[type=datetime],
.km-list .km-state-active input[type=datetime-local],
.km-list .km-state-active input[type=text]:not(.k-input),
.km-list .km-state-active select:not([multiple]),
.km-list .km-state-active textarea {
  color: #fff;
}
/* Checkboxes and Radios */
.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox] {
  border-color: rgba(68, 68, 68, 0.2);
  background: #fff;
}
.km-listview-label input[type=checkbox]:checked:after {
  color: #eee;
}
/* Shim */
.km-shim {
  background: rgba(0, 0, 0, 0.4);
}
/* PopUp + ActionSheet */
.km-popup .k-item,
.km-widget.km-actionsheet-wrapper,
.km-widget.km-actionsheet > li > a {
  background: #ffffff;
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.km-actionsheet-cancel > a {
  color: #bababa;
  -webkit-box-shadow: inset 0 0 300px rgba(128, 128, 128, 0.3);
          box-shadow: inset 0 0 300px rgba(128, 128, 128, 0.3);
}
.km-popup {
  background: rgba(0, 0, 0, 0.4);
}
.km-actionsheet-wrapper,
.km-popup .k-list-container {
  background: rgba(0, 0, 0, 0.4);
  border-top-color: rgba(255, 255, 255, 0.2);
}
/* PopOver */
.km-popup.km-pane {
  border-color: #ffffff;
  background-color: #ffffff;
}
.km-popup-arrow:after,
.km-popup-arrow:before {
  border-color: rgba(0, 0, 0, 0.5) transparent;
}
.km-popup-arrow:after {
  border-color: #ffffff transparent;
}
.km-left .km-popup-arrow:after,
.km-right .km-popup-arrow:after {
  border-color: transparent #ffffff;
}
.km-down .km-popup-arrow:after {
  border-color: #ffffff transparent;
}
/* Loader & Pull-to-refresh */
.km-loader {
  background: rgba(0, 0, 0, 0.05);
}
.km-loader h1 {
  color: #444444;
}
/* Active States */
.km-tabstrip {
  background: #ffffff;
}
.km-badge,
.km-rowinsert,
.km-rowdelete,
html .km-state-active,
.km-switch-label-on,
.km-switch-label-off,
.km-tabstrip .km-button,
.km-popup .k-item,
.km-actionsheet > li > a,
.km-tabstrip .km-state-active,
.km-scroller-pull .km-icon,
.km-popover-root .km-widget,
.km-popup .k-state-hover,
.km-popup .k-state-focused,
.km-popup .k-state-selected,
.km-actionsheet > li > a:active,
.km-actionsheet > li > a:hover,
li.km-state-active .km-listview-link,
li.km-state-active .km-listview-label,
.km-state-active .km-listview-link:after {
  color: #3f51b5;
}
.km-loader > *:not(h1),
.km-filter-wrap > input,
.km-root .km-scroller-refresh span:not(.km-template) {
  background-color: #3f51b5;
}
.km-switch-handle.km-state-active {
  background-color: #ffffff;
}
.km-switch-handle,
.km-switch-background {
  color: #fff;
}
.km-rowinsert,
.km-state-active,
.km-scroller-pull,
.km-loader:before,
.km-touch-scrollbar,
.km-pages .km-current-page,
.km-tabstrip .km-state-active,
.km-scroller-refresh.km-load-more,
.km-popup .k-state-hover,
.km-popup .k-state-focused,
.km-popup .k-state-selected,
.km-actionsheet > li > a:active,
.km-actionsheet > li > a:hover,
li.km-state-active .km-listview-link,
li.km-state-active .km-listview-label,
.km-listview-label input[type=radio]:checked,
.km-listview-label input[type=checkbox]:checked {
  background: #fff;
}
.km-filter-wrap > input:focus {
  border-color: #fff;
}
.km-badge,
.km-rowdelete {
  background: #da2228;
}
